import React, { useState } from "react";

import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import {
  addBlog,
  addBlogImage,
  addFeaturedVideo,
  uploadVideo,
  videoThumbnail,
} from "../../../../redux/slices/blog.slice";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Loader from "../../../../components/Loader";

export default function Form({
  state,
  stateChangeHandler,
  videoLoading,
  imageLoading,
  featuredVideoLoading,
  handleImageFileChange,
  handleReelFileChange,
  uploadFeaturedVideo,
  edit,
  updateVideoTitle,
}) {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 24,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={state?.title}
        onChange={(e) => {
          stateChangeHandler("title", e.target.value);
        }}
      />
      {!edit && (
        <>
          <TextInput
            label="Button Title (Optional)"
            className={""}
            name="text"
            type="text"
            placeholder="Button Title"
            required
            value={state?.buttonTitle}
            onChange={(e) => {
              stateChangeHandler("buttonTitle", e.target.value);
            }}
          />
          {state.buttonTitle && (
            <TextInput
              label="Button Link"
              className={""}
              name="text"
              type="text"
              placeholder="Button Link"
              required
              value={state?.buttonLink}
              onChange={(e) => {
                stateChangeHandler("buttonLink", e.target.value);
              }}
            />
          )}
          {!state?.reelUrl && (
            <File
              title={state?.imageUrl ? "Image Uploaded" : "Upload Image"}
              accept={"image/*"}
              videoLoading={imageLoading}
              onChange={handleImageFileChange}
              label={"Image (Optional)"}
            />
          )}
          {!state?.imageUrl && (
            <File
              title={
                state?.reelUrl
                  ? "Video Uploaded"
                  : state?.video?.name
                  ? state?.video?.name?.slice(0, 6) + "..."
                  : "Reel Video"
              }
              videoLoading={videoLoading}
              onChange={handleReelFileChange}
              label={"Reel Video (Optional)"}
              accept={"video/*"}
            />
          )}
        </>
      )}
      <button
        onClick={edit ? updateVideoTitle : uploadFeaturedVideo}
        disabled={videoLoading || featuredVideoLoading}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          // padding: 10,
          height: 40,
          cursor: videoLoading || featuredVideoLoading ? "no-drop" : "pointer",
          // alignSelf: "flex-start",
        }}
      >
        {featuredVideoLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : edit ? (
          "Edit Sponsored Title"
        ) : (
          " Add Sponsored Video"
        )}
      </button>
    </div>
  );
}
