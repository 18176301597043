import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import SignUp from "./newScreens/SignUp";
import SignIn from "./newScreens/SignIn";
import Video from "./newScreens/Video";
import PrivateRoute from "./navigation/PrivateRouting";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store";
import { Toaster } from "react-hot-toast";
import Subscription from "./newScreens/subscription";
import Contact from "./newScreens/contacts";
import AddBlog from "./newScreens/addBlog";
import FeaturedVideo from "./newScreens/featuredVideo";
import Music from "./newScreens/music";
import Category from "./newScreens/categories";
import Audio from "./newScreens/audio";
import { redirect } from "./redux/slices/auth.slice";
import { useEffect } from "react";
import Routing from "./navigation/Routing";
import './style.css'
function App() {
  localStorage.setItem("darkMode", false);
  return (
    <Provider store={store}>
      <Toaster />
      <Routing />
    </Provider>
  );
}

export default App;
