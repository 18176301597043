import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import videoSlice from "./slices/video.slice";
import subscriptionSlice from "./slices/subscription.slice";
import contactSlice from "./slices/contact.slice";
import blogSlice from "./slices/blog.slice";
import categorySlice from "./slices/category.slice";
import audioSlice from "./slices/audio.slice";
import musicSlice from "./slices/music.slice";
import tagsSlice from "./slices/tags.slice";
import adsSlice from "./slices/ads.slice";
import usersSlice from "./slices/users.slice";
import influencerSlice from "./slices/influencity.slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    video: videoSlice,
    audio: audioSlice,
    subscription: subscriptionSlice,
    contacts: contactSlice,
    blogs: blogSlice,
    category: categorySlice,
    music: musicSlice,
    tags: tagsSlice,
    users: usersSlice,
    ads: adsSlice,
    influencer: influencerSlice,
  },
});
