import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideos,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlog,
  getBlogs,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import cn from "classnames";
import {
  deleteAudio,
  getAudio,
  searchAudio,
  uploadAudioMusic,
} from "../../../redux/slices/audio.slice";
import SearchForm from "../../../components/Form";
import ReactPaginate from "react-paginate";
const Table = () => {
  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { audio, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.audio
  );

  //
  const [uploadLoading, setUploadLoading] = useState(false);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [state, setState] = useState({
    title: "",
    audio: "",
    thumbnail: "",
  });
  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    stateChangeHandler("audio", file);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    stateChangeHandler("thumbnail", file);
  };

  const getAudioData = (currentPage) => {
    dispatch(
      getAudio({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
          } else {
          }
        },
      })
    );
  };
  const uploadAudio = () => {
    if (!state?.title) {
      toast.error("Title is required");
    }
    if (!state?.audio) {
      toast.error("Audio is required");
    } else {
      try {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("title", state.title);
        formData.append("audio", state.audio);
        if (state.thumbnail) {
          formData.append("thumbnail", state.thumbnail);
        }
        dispatch(
          uploadAudioMusic({
            payload: formData,
            callback: (data) => {
              if (data?.status === 201) {
                setUploadLoading(false);
                setAddVideoModal(false);
                getAudioData(currentPage);
                setState({
                  title: "",
                  audio: "",
                  thumbnail: "",
                });
              } else {
                setUploadLoading(false);
                toast.error("Something went wrong");
              }
            },
          })
        );
      } catch (error) {
        setUploadLoading(false);
        toast.error("Something went wrong");
      }
    }
  };
  const ctaDeleteHandler = (x) => {
    try {
      dispatch(
        deleteAudio({
          payload: {
            id: x?.id,
          },
          callback: (data) => {
            if (data?.status === 200) {
              toast.success("Audio Deleted Successfully");
              getAudioData(currentPage);
            } else {
              toast.error("Something went wrong");
            }
          },
        })
      );
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getAudioData(currentPage);
  }, [dispatch, currentPage]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getAudio({
            payload: "true",
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchAudio({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  return (
    <div>
      <Modal
        visible={addVideoModal}
        onClose={() => setAddVideoModal(false)}
        title="Add Audio"
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            state={state}
            stateChangeHandler={stateChangeHandler}
            handleFileChange={handleFileChange}
            handleImageChange={handleImageChange}
            uploadVideo={uploadAudio}
            videoUploadLoading={uploadLoading}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Audio"
          type="text"
          name="search"
          icon="search"
        />
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setAddVideoModal(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Video">
              Audio
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Thumbnail
            </div>
            {/* <div className={styles.col} data-label="Status">
              Status
            </div>
            <div className={styles.col} data-label="Featured">
              Sponsored
            </div> */}
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Created At">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : audio?.length > 0 ? (
            audio?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p>{x?.title}</p>
                </div>
                <div className={styles.col} data-label="Video">
                  <a
                    href={x?.audioUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    Audio_URL
                  </a>
                </div>
                <div className={styles.col} data-label="Thumbnail">
                  {/* <a
                    href={x?.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    Thumbnail_URL
                  </a> */}
                  <a
                    href={x?.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    <img src={x?.thumbnailUrl} height={140} width={160} />
                  </a>
                </div>
                {/* <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.verified ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.verified ? "Verified" : "Pending"}
                  </span>
                </div>
                <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.featured ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.featured ? "Yes" : "No"}
                  </span>
                </div> */}
                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => ctaDeleteHandler(x)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
