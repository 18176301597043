import React, { useState } from "react";
import cn from "classnames";
import styles from "../TextInput/TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const TagInput = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  // value,
  // onChange,
  tags,
  setTags,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newTags = inputValue
        .trim()
        .split(",")
        .filter((tag) => tag !== "")
        .map((tag) => `${tag}`);
      setTags([...tags, ...newTags]);
      setInputValue("");
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() !== "") {
      const newTags = inputValue
        .trim()
        .split(",")
        .filter((tag) => tag !== "")
        .map((tag) => `${tag}`);
      setTags([...tags, ...newTags]);
      setInputValue("");
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div>
      <div
        className={cn(
          styles.field,
          { [styles.fieldIcon]: icon },
          { [styles.fieldCopy]: copy },
          { [styles.fieldCurrency]: currency },
          className
        )}
      >
        {label && (
          <div className={cn(classLabel, styles.label)}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place={place ? place : "right"}
              />
            )}
          </div>
        )}
        <div className={styles.wrap}>
          <input
            className={cn(classInput, styles.input)}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            // value={value}
            {...props}
            // onChange={onChange}
            placeholder="Type tags separated by enter"
          />
        </div>
      </div>

      <div style={{ marginTop: 10 }}>
        {tags.map((tag, index) => (
          <span
            key={index}
            style={{
              display: "inline-block",
              padding: "5px",
              margin: "5px",
              backgroundColor: "#e0e0e0",
              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={() => removeTag(index)}
          >
            {tag} &times;
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
