import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  subscriptions: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/subscription?page=${data.payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${endpoint}/v1/subscription/delete-subscription/${data?.payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubscription.fulfilled, (state, { payload }) => {
      state.subscriptions = payload?.data;
      state.page = payload?.data?.page;
      state.pageSize = payload?.data?.pageSize;
      state.totalPages = payload?.data?.totalPages;
      state.loading = false;
    });
    builder.addCase(getSubscription.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
