import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideos,
  uploadSharpImage,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlog,
  addBlogImage,
  getBlogs,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import cn from "classnames";
import {
  addTag,
  deleteAudio,
  deleteTag,
  getTags,
  searchTags,
  updateTag,
  uploadAudioMusic,
} from "../../../redux/slices/tags.slice";
import SearchForm from "../../../components/Form";
import { addAdsItem, deleteAd, getAds } from "../../../redux/slices/ads.slice";
import ReactPaginate from "react-paginate";
const Table = () => {
  const dispatch = useDispatch();
  const [adsLoading, setAdsLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  // const [tagName, setTagName] = useState("");
  const [state, setState] = useState({
    link: "",
    imageFile: null,
    videoFile: null,
    imageUrl: "",
    videoUrl: "",
  });
  const stateChangeHandler = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { ads, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.ads
  );

  //
  const getAdsData = (currentPage) => {
    dispatch(
      getAds({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getAdsData(currentPage);
  }, [dispatch,currentPage]);

  const handleReelImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    try {
      if (state?.videoUrl || state.videoFile) {
        toast.error("Image and Video cannot be uploaded at once");
        return false;
      } else {
        setImageLoading(true);
        stateChangeHandler("imageFile", file);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "video");
        dispatch(
          addBlogImage({
            payload: formData,
            callback: (dataInner) => {
              if (dataInner?.status === 200) {
                stateChangeHandler("imageUrl", dataInner?.imageUrl);
                setImageLoading(false);
              }
            },
          })
        );
      }
    } catch (error) {
      console.error("Error handling file change:", error);
      toast.error("An error occurred while handling the file change");
      setImageLoading(false);
    }
  };
  const handleReelFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    try {
      if (state?.imageUrl || state.imageFile) {
        toast.error("Image and Video cannot be uploaded at once");
        return false;
      } else {
        setVideoLoading(true);
        const formData = new FormData();
        stateChangeHandler("videoFile", file);
        if (file.type === "video/mp4") {
          formData.append("file", file);
          formData.append("type", "music");

          dispatch(
            uploadVideo({
              payload: formData,
              callback: (data) => {
                if (data?.status === 200) {
                  stateChangeHandler("videoUrl", data?.videoUrl);
                  setVideoLoading(false);
                } else {
                  toast.error(data?.message);
                  setVideoLoading(false);
                }
              },
            })
          );
        } else {
          toast.error("File must be mp4");
          setVideoLoading(false);
        }
      }
    } catch (error) {
      console.error("Error handling file change:", error);
      toast.error("An error occurred while handling the file change");
      setVideoLoading(false);
    }
  };
  const addAds = () => {
    try {
      if (!state.link) {
        toast.error("Link Required");
        return false;
      } else if (!state.videoUrl && !state.imageUrl) {
        toast.error("Image Required");
        return false;
      } else if (!state.videoUrl && !state.imageUrl) {
        toast.error("Video Required");
        return false;
      } else {
        setAdsLoading(true);
        dispatch(
          addAdsItem({
            payload: {
              link: state?.link,
              videoUrl: state?.videoUrl,
              imageUrl: state?.imageUrl,
            },
            callback: (data) => {
              if (data?.status === 200) {
                setAdsLoading(false);
                toast.success("Ad added successfully");
                setState({
                  link: "",
                  imageFile: null,
                  videoFile: null,
                  imageUrl: "",
                  videoUrl: "",
                });
                getAdsData(currentPage);
                setAddVideoModal(false);
              } else {
                setAdsLoading(false);
                toast.error("Failed to add Ad");
              }
            },
          })
        );
      }
    } catch (error) {
      setAdsLoading(false);
      toast.error("Failed to add Ad");
    }
  };

  const ctaDeleteHandler = (x) => {
    try {
      dispatch(
        deleteAd({
          payload: {
            id: x?.id,
          },
          callback: (data) => {
            if (data?.status === 200) {
              getAdsData(currentPage);
              toast.success("Ad deleted successfully");
            } else {
              toast.error("Failed to delete Ad");
            }
          },
        })
      );
    } catch (error) {
      toast.error("Failed to delete Ad");
    }
  };

  const ctaEditHandler = (x) => {
    setEditData(x);
    setAddVideoModal(true);
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  // const updateTagName = () => {
  //   try {
  //     setAdsLoading(true);
  //     dispatch(
  //       updateTag({
  //         payload: {
  //           id: editData?.id,
  //           // name: tagName,
  //           status: editData?.status,
  //         },
  //         callback: (data) => {
  //           if (data?.status === 200) {
  //             getAdsData();
  //             setAdsLoading(false);
  //             setAddVideoModal(false);
  //             toast.success("Tag updated successfully");
  //           } else {
  //             setAdsLoading(false);
  //             toast.error("Failed to update tag");
  //           }
  //         },
  //       })
  //     );
  //   } catch (error) {
  //     setAdsLoading(false);
  //     toast.error("Failed to update tag");
  //   }
  // };
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getTags({
            payload: "true",
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchTags({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  return (
    <div>
      <Modal
        visible={addVideoModal}
        onClose={() => {
          setEditData("");
          setAddVideoModal(false);
        }}
        title={editData ? "Edit Ad" : "Add Ad"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            isEdit={editData}
            loading={adsLoading}
            tags={tagsList}
            setTags={setTagsList}
            setState={setState}
            state={state}
            stateChangeHandler={stateChangeHandler}
            // updateTagName={updateTagName}
            videoLoading={videoLoading}
            imageLoading={imageLoading}
            handleReelFileChange={handleReelFileChange}
            handleReelImageChange={handleReelImageChange}
            addAds={addAds}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        {/* <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Tag"
          type="text"
          name="search"
          icon="search"
        /> */}
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setAddVideoModal(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Link
            </div>
            <div className={styles.col} data-label="Video">
              Image/Video
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Created At
            </div>

            <div className={styles.col} data-label="Created At">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : ads?.length > 0 ? (
            ads?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <a href={x?.link} target="blank">
                    link
                  </a>
                </div>
                <div className={styles.col} data-label="Status">
                  <a href={x?.imageUrl || x?.videoUrl} target="blank">
                    Ad Url
                  </a>
                </div>

                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => ctaDeleteHandler(x)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>
                    {/* <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColorGreen}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}  // Highlight active tab
      />
    </div>
  );
};

export default Table;
