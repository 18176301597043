import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import { useSelector } from "react-redux";

const navigation = [
  {
    title: "Post A Articles",
    icon: "video",
    url: "/",
  },
  {
    title: "Post A Peeks (Video)",
    icon: "video",
    url: "/peeks",
  },
  {
    title: "Post A Sponsored Ad (Peek)",
    icon: "video",
    url: "/sponsored-ads",
  },
  {
    title: "Music Videos",
    icon: "video",
    url: "/music-videos",
  },
  {
    title: "Music Tracks",
    icon: "diamond",
    url: "/music",
  },
  {
    title: "Categories",
    icon: "list",
    url: "/categories",
  },
  {
    title: "Subscription Emails",
    icon: "heart",
    url: "/subscription",
  },
  {
    title: "Messages",
    icon: "messages",
    url: "/contact",
  },
  {
    title: "Settings",
    slug: "setting",
    icon: "setting",
    // add: true,
    dropdown: [
      {
        title: "Change Password",
        url: "/setting/change-password",
      },
      {
        title: "Update Profile",
        url: "/setting/update-profile",
      },
      {
        title: "Maintenance Mode",
        url: "/setting/maintenance-mode",
      },
    ],
  },
  // {
  //   title: "Settings",
  //   icon: "setting",
  //   url: "/change-password",
  // },
  {
    title: "Tags",
    icon: "ticket",
    url: "/tags",
  },
  {
    title: "Influasity",
    icon: "diamond",
    url: "/influasity ",
  },
  {
    title: "AD Placement",
    icon: "setting",
    url: "/ad-placement",
  },
  {
    title: "Users",
    icon: "setting",
    url: "/users",
  },
  {
    title: "Files",
    icon: "setting",
    url: "/files",
  },
  {
    title: "Statistics",
    icon: "setting",
    url: "/statistics",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/iconLogo.svg"
            // srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation
            .filter((item) => {
              if (auth?.role != "SuperAdmin") {
                return item?.title != "Users";
              } else {
                return item;
              }
            })
            .map((x, index) =>
              x.url ? (
                <NavLink
                  className={cn(styles.item, {
                    [styles.active]: pathname === x.url,
                  })}
                  to={x.url}
                  key={index}
                  onClick={onClose}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              )
            )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <p className={styles.item}>Hello, {auth?.name}</p>
          {/* <Theme className={styles.theme} visibleSidebar={visible} /> */}
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
