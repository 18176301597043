import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Item from "./Item";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getVideos, verifyVideo } from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import { getSubscription } from "../../../redux/slices/subscription.slice";
import { getContacts } from "../../../redux/slices/contact.slice";
import Loader from "../../../components/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import ReactPaginate from "react-paginate";

const Table = () => {
  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { contacts, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.contacts
  );

  //

  const getContactData = (currentPage) => {
    dispatch(
      getContacts({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };
  const exportToCSV = () => {
    // Convert contact data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(contacts);

    // Convert the worksheet to CSV
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a download link for the CSV file
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "messages_data.csv"; // Specify the file name

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  useEffect(() => {
    getContactData(currentPage);
  }, [dispatch,currentPage]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: 10,
        }}
      >
        <button
          onClick={exportToCSV}
          style={{
            backgroundColor: "#FF3C00",
            padding: 10,
            color: "white",
            borderRadius: 6,
            outline: "none",
          }}
        >
          Export
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Email
            </div>
            <div className={styles.col} data-label="Title">
              Name
            </div>
            <div className={styles.col} data-label="Title">
              Message
            </div>
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : contacts?.length > 0 ? (
            contacts?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p>{x?.email}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.name}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.message}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.createdAt}</p>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}  // Highlight active tab
      />
    </div>
  );
};

export default Table;
