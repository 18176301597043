import React from "react";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Editor from "../../../../components/Editor";
import Loader from "../../../../components/Loader";
import Dropdown from "../../../../components/Dropdown";

export default function Form({
  state,
  stateChangeHandler,
  handleFileChange,
  videoLoading,
  uploadVideo,
  videoUploadLoading,
  handleImageChange,
  options,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={state?.title}
        onChange={(e) => {
          stateChangeHandler("title", e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <File
            title={
              state?.thumbnail?.name
                ? state?.thumbnail?.name?.slice(0, 6) + "..."
                : "Thumnail"
            }
            accept={"image/*"}
            videoLoading={videoLoading}
            onChange={handleImageChange}
            label={"Thumnail (optional)"}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <File
            title={
              state?.audio?.name
                ? state?.audio?.name?.slice(0, 6) + "..."
                : "Audio"
            }
            videoLoading={videoLoading}
            onChange={handleFileChange}
            label={"Audio"}
            accept={"audio/*"}
          />
        </div>
      </div>
      <button
        style={{
          backgroundColor: "#ff3c00",
          width: "100%",
          height: 50,
          borderRadius: 30,
          color: "white",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          justifyContent: "center",
          opacity: videoUploadLoading ? 0.5 : 1,
          cursor: videoUploadLoading ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (videoUploadLoading) {
            return;
          } else {
            uploadVideo();
          }
        }}
        disabled={videoUploadLoading}
      >
        {videoUploadLoading ? <Loader white={"white"} /> : "Upload Audio"}
      </button>
    </div>
  );
}
