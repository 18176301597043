import React from "react";
import Table from "./Table/index";

export default function Tags() {
  return (
    <div>
      <Table />
    </div>
  );
}
