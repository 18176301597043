import React, { useState } from "react";
import TagInput from "../../../../components/tagInput/TagInput";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";

export default function Form({ loading, onClick, state, setState, editData }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {!editData && (
        <TextInput
          label="Name"
          className={""}
          name="text"
          type="text"
          placeholder="Name"
          required
          value={state?.name}
          onChange={(e) => {
            setState((prevState) => ({ ...prevState, name: e.target.value }));
          }}
        />
      )}
      {!editData && (
        <TextInput
          label="Email"
          className={""}
          name="text"
          type="text"
          placeholder="Email"
          required
          value={state?.email}
          onChange={(e) => {
            setState((prevState) => ({ ...prevState, email: e.target.value }));
          }}
        />
      )}
      <TextInput
        label="Password"
        className={""}
        name="text"
        type="text"
        placeholder="Password"
        required
        value={state?.password}
        onChange={(e) => {
          setState((prevState) => ({ ...prevState, password: e.target.value }));
        }}
      />
      <button
        onClick={onClick}
        disabled={false}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          cursor: loading ? "no-drop" : "pointer",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : editData ? (
          "Change Password"
        ) : (
          "Add Admin"
        )}
      </button>
    </div>
  );
}
