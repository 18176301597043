// import React, { useState } from "react";
// import cn from "classnames";
// import OutsideClickHandler from "react-outside-click-handler";
// import styles from "./Dropdown.module.sass";
// import Tooltip from "../Tooltip";

// const MultiSelectDropdown = ({
//   className,
//   classDropdownHead,
//   classDropdownLabel,
//   value,
//   setValue,
//   options,
//   label,
//   tooltip,
//   small,
//   upBody,
//   tags,
//   setTags,
// }) => {
//   const [visible, setVisible] = useState(false);

//   const handleClick = (newvalue) => {
//     setTags([...tags, newvalue?.name]);
//     setValue(newvalue?.name);
//     setVisible(false);
//   };
//   const removeTag = (indexToRemove) => {
//     setValue(tags?.filter((_, index) => index !== indexToRemove));
//   };
//   return (
//     <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
//       {label && (
//         <div className={cn(styles.label, classDropdownLabel)}>
//           {label}{" "}
//           {tooltip && (
//             <Tooltip
//               className={styles.tooltip}
//               title={tooltip}
//               icon="info"
//               place="right"
//             />
//           )}
//         </div>
//       )}
//       <div
//         style={{
//           backgroundColor: "#F4F4F4",
//           borderRadius: 10,
//         }}
//         className={cn(
//           styles.dropdown,
//           className,
//           { [styles.small]: small },
//           {
//             [styles.active]: visible,
//           }
//         )}
//       >
//         <div
//           className={cn(styles.head, classDropdownHead)}
//           onClick={() => setVisible(!visible)}
//         >
//           <div className={styles.selection}>
//             {value?.title ? value?.title : value}
//           </div>
//         </div>
//         <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
//           <div
//             className={cn(styles.option)}
//             onClick={() => setValue("")}
//             key={0}
//           >
//             Select
//           </div>
//           {options?.map((x, index) => (
//             <div
//               className={cn(styles.option, {
//                 [styles.selectioned]: x === value,
//               })}
//               onClick={() => handleClick(x, index)}
//               key={index}
//             >
//               {x?.name ? x?.name : x}
//             </div>
//           ))}
//         </div>

//       </div>
//       <div style={{ marginTop: 10 }}>
//           {tags.map((tag, index) => (
//           <span
//             key={index}
//             style={{
//               display: "inline-block",
//               padding: "5px",
//               margin: "5px",
//               backgroundColor: "#e0e0e0",
//               borderRadius: "3px",
//               cursor: "pointer",
//             }}
//             onClick={() => removeTag(index)}
//           >
//             {tag} &times;
//           </span>
//         ))}
//         </div>
//     </OutsideClickHandler>
//   );
// };

// export default MultiSelectDropdown;

import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Icon from "../Icon";

const MultiSelectDropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  tags,
  setTags,
  handleOpenTagModal,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (newvalue) => {
    setTags([...tags, newvalue?.name]);
    setValue(newvalue?.name);
    setVisible(false);
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        style={{
          backgroundColor: "#F4F4F4",
          borderRadius: 10,
        }}
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            {value?.title ? value?.title : value}
          </div>
        </div>
        {visible && (
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
            <div
              className={cn(styles.option)}
              onClick={() => {
                setValue("");
                handleOpenTagModal();
              }}
              key={1}
            >
              <Icon name="plus" size="14" fill="grey" /> Add Tag
            </div>
            <div
              className={cn(styles.option)}
              onClick={() => setValue("")}
              key={0}
            >
              Select
            </div>
            {options?.map((x, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]: x === value,
                })}
                onClick={() => handleClick(x, index)}
                key={index}
              >
                {x?.name ? x?.name : x}
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {tags.map((tag, index) => (
          <span
            key={index}
            style={{
              display: "inline-block",
              padding: "5px",
              margin: "5px",
              backgroundColor: "#e0e0e0",
              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={() => removeTag(index)}
          >
            {tag} &times;
          </span>
        ))}
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelectDropdown;
