import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "../styles/app.sass";
import Page from "../components/Page";
import SignUp from "../newScreens/SignUp";
import SignIn from "../newScreens/SignIn";
import Video from "../newScreens/Video";
import PrivateRoute from "../navigation/PrivateRouting";
import { useDispatch, useSelector } from "react-redux";
import Subscription from "../newScreens/subscription";
import Contact from "../newScreens/contacts";
import AddBlog from "../newScreens/addBlog";
import FeaturedVideo from "../newScreens/featuredVideo";
import Music from "../newScreens/music";
import Category from "../newScreens/categories";
import Audio from "../newScreens/audio";
import { redirect } from "../redux/slices/auth.slice";
import { useEffect, useState } from "react";
import ChangePassword from "../newScreens/changePassword";
import Tags from "../newScreens/tags";
import AdsPlacement from "../newScreens/ads-placement";
import MaintenanceMode from "../newScreens/maintenanceMode/MaintenanceMode";
import Users from "../newScreens/users";
import Influencity from "../newScreens/influencity";
import UpdateProfile from "../newScreens/updateProfile";
export default function Routing() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      redirect({
        payload: "",
        callback: (data) => {
          if (!data?.data) {
            localStorage.removeItem("j-token");
          } else {
          }
        },
      })
    );
  }, []);

  return (
    <Routes>
      {/* Public Routes */}
      {/* <Route path="/sign-up" element={<SignUp />} /> */}
      <Route path="/sign-in" element={<SignIn />} />

      {/* Private Routes */}
      {/* <Route
      path="/"
      element={
        <PrivateRoute>
          <Page title="Dashboard">
            <Home />
          </Page>
        </PrivateRoute>
      }
    /> */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Page title="Articles">
              <AddBlog />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/peeks"
        element={
          <PrivateRoute>
            <Page title="Peeks">
              <Video />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <PrivateRoute>
            <Page title="Categories">
              <Category />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <PrivateRoute>
            <Page title="subscriptions">
              <Subscription />
            </Page>
          </PrivateRoute>
        }
      />

      <Route
        path="/music-videos"
        element={
          <PrivateRoute>
            <Page title="Music Videos">
              <Music />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <PrivateRoute>
            <Page title="Subscriptions">
              <Subscription />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/contact"
        element={
          <PrivateRoute>
            <Page title="Messages">
              <Contact />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/sponsored-ads"
        element={
          <PrivateRoute>
            <Page title="Sponsored Ads">
              <FeaturedVideo />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/music"
        element={
          <PrivateRoute>
            <Page title="Music">
              <Audio />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/setting/change-password"
        element={
          <PrivateRoute>
            <Page title="Change Password">
              <ChangePassword />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/setting/update-profile"
        element={
          <PrivateRoute>
            <Page title="Update Profile">
              <UpdateProfile />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/setting/maintenance-mode"
        element={
          <PrivateRoute>
            <Page title="Maintenance Mode">
              <MaintenanceMode />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/tags"
        element={
          <PrivateRoute>
            <Page title="Tags">
              <Tags />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/influasity"
        element={
          <PrivateRoute>
            <Page title="Influasity">
              <Influencity />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/ad-placement"
        element={
          <PrivateRoute>
            <Page title="AD Placement">
              <AdsPlacement />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Page title="Users">
              <Users />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/files"
        element={
          <PrivateRoute>
            <Page title="Files">
              <p></p>
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path="/statistics"
        element={
          <PrivateRoute>
            <Page title="Statistics">
              <a
                href="https://mixpanel.com/project/3344325/view/3851773/app/home"
                target="blank"
              >
                Click Here for stats
              </a>
            </Page>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
