import React, { useState } from "react";
import TagInput from "../../../../components/tagInput/TagInput";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";

export default function Form({
  loading,
  tags,
  setTags,
  onClick,
  editData,
  tagName,
  setTagName,
  updateTagName,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {!editData && (
        <TagInput
          label="Tags"
          className={""}
          name="text"
          type="text"
          placeholder="Tags"
          required
          tags={tags}
          setTags={setTags}
        />
      )}
      {editData && (
        <>
          <TextInput
            label="Name"
            className={""}
            name="text"
            type="text"
            placeholder="Name"
            required
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value);
            }}
          />
        </>
      )}
      <button
        onClick={editData ? updateTagName : onClick}
        disabled={false}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          cursor: loading ? "no-drop" : "pointer",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : editData ? (
          "Update Tag"
        ) : (
          "Add tags"
        )}
      </button>
    </div>
  );
}
