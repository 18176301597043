import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  blogs: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const searchBlog = createAsyncThunk(
  "blog/searchBlog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/blog/search-blog?title=${data?.payload}
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateManyBlog = createAsyncThunk(
  "blog/updateManyBlog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/update-many-blog
        `,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addBlog = createAsyncThunk(
  "blog/addblog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v2/blog/post
        `,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const modifyBlog = createAsyncThunk(
  "blog/modifyBlog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/update-blog
        `,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addFeaturedVideo = createAsyncThunk(
  "featured/featuredVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v2/video/post-sponsored-video
        `,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const videoThumbnail = createAsyncThunk(
  "video/compressVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/video/cut-thumbnail-video`,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const uploadVideo = createAsyncThunk(
  "blog/uploadVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/upload-video
        `,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      throw new Error("Something went wrong")
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "blog/getBlogs",
  async (data, thunkAPI) => {
    console.log("data", data);
    try {
      // Construct the query parameters properly
      let queryParams = '';

      if (data?.payload?.filter) {
        queryParams += `?status=${data.payload.filter}`;
      }

      if (data?.payload?.page) {
        queryParams += queryParams ? `&page=${data.payload.page}` : `?page=${data.payload.page}`;
      }

      const response = await axios.get(
        `${endpoint}/v1/blog/get-blogs${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );

      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/${data?.payload.id}
        `,
        {
          status: data?.payload?.status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteBlog = createAsyncThunk(
  "blog/deleteBlog",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/delete-blog/${data?.payload.id}
        `,
        {
          status: data?.payload?.status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addBlogImage = createAsyncThunk(
  "blog/uploadBlogImage",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/upload-image
        `,
        data.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteBlogComment = createAsyncThunk(
  "blog/deleteComment",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/blog/delete-comment
        `,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlogs.fulfilled, (state, { payload }) => {
      state.blogs = payload?.data;
      state.loading = false;
      state.page = payload?.page;
      state.pageSize = payload?.pageSize;
      state.totalPages = payload?.totalPages;
    });
    builder.addCase(getBlogs.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(searchBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchBlog.fulfilled, (state, { payload }) => {
      state.blogs = payload?.blogs;
      state.loading = false;
    });
    builder.addCase(searchBlog.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = blogSlice.actions;
export default blogSlice.reducer;
