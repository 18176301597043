// // // // import React, { useState, useEffect, useRef } from "react";
// // // // import { Editor } from "react-draft-wysiwyg";
// // // // import { EditorState, convertToRaw } from "draft-js";
// // // // import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// // // // import axios from "axios";
// // // // import { endpoint } from "../../config/endpoint";

// // // // const EditorComponent = ({ label, state, onChange }) => {
// // // //   const editorContainerRef = useRef(null);
// // // //   const uploadImageCallBack = (file) => {
// // // //     return new Promise((resolve, reject) => {
// // // //       const formData = new FormData();
// // // //       formData.append("file", file);
// // // //       axios
// // // //         .post(`${endpoint}/v1/blog/upload-image`, formData, {
// // // //           headers: {
// // // //             "Content-Type": "multipart/form-data",
// // // //             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
// // // //           },
// // // //         })
// // // //         .then((response) => {
// // // //           resolve({ data: { link: response.data.imageUrl } });
// // // //         })
// // // //         .catch((error) => {
// // // //           reject(error);
// // // //         });
// // // //     });
// // // //   };
// // // //   return (
// // // //     <div>
// // // //       <label
// // // //         style={{
// // // //           opacity: 0.9,
// // // //           marginTop: 10,
// // // //         }}
// // // //       >
// // // //         {label}
// // // //       </label>
// // // //       <div
// // // //         className="editor"
// // // //         style={{
// // // //           minHeight: "200px",
// // // //           backgroundColor: "#F4F4F4",
// // // //           borderRadius: 15,
// // // //           marginTop: 12,
// // // //         }}
// // // //         ref={editorContainerRef}
// // // //       >
// // // //         <Editor
// // // //           editorClassName="editor-container"
// // // //           editorState={state}
// // // //           onEditorStateChange={(newState) => {
// // // //             onChange(newState);
// // // //           }}
// // // //           toolbar={{
// // // //             inline: { inDropdown: true },
// // // //             list: { inDropdown: true },
// // // //             textAlign: { inDropdown: true },
// // // //             link: { inDropdown: true },
// // // //             image: {
// // // //               previewImage: true,
// // // //               uploadCallback: uploadImageCallBack,
// // // //               alt: { present: false, mandatory: false },

// // // //             },
// // // //           }}
// // // //         />
// // // //         {/* <textarea
// // // //         value={draftToHtml(convertToRaw(state?.getCurrentContent()))}
// // // //       /> */}
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default EditorComponent;

// // // import React, { useRef } from "react";
// // // import { Editor } from "react-draft-wysiwyg";
// // // import { EditorState, AtomicBlockUtils } from "draft-js";
// // // import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// // // import axios from "axios";
// // // import { endpoint } from "../../config/endpoint";

// // // const EditorComponent = ({ label, state, onChange }) => {
// // //   const editorContainerRef = useRef(null);

// // //   const uploadImageCallBack = (file) => {
// // //     return new Promise((resolve, reject) => {
// // //       const formData = new FormData();
// // //       formData.append("file", file);
// // //       axios
// // //         .post(`${endpoint}/v1/blog/upload-image`, formData, {
// // //           headers: {
// // //             "Content-Type": "multipart/form-data",
// // //             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
// // //           },
// // //         })
// // //         .then((response) => {
// // //           resolve({ data: { link: response.data.imageUrl } });
// // //         })
// // //         .catch((error) => {
// // //           reject(error);
// // //         });
// // //     });
// // //   };

// // //   const handleImageUpload = (files) => {
// // //     const promises = [];
// // //     for (let i = 0; i < files.length; i++) {
// // //       promises.push(uploadImageCallBack(files[i]));
// // //     }
// // //     Promise.all(promises)
// // //       .then((results) => {
// // //         results.forEach((result) => {
// // //           const contentState = state.getCurrentContent();
// // //           const contentStateWithEntity = contentState.createEntity(
// // //             "IMAGE",
// // //             "IMMUTABLE",
// // //             { src: result.data.link }
// // //           );
// // //           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
// // //           const newState = AtomicBlockUtils.insertAtomicBlock(
// // //             state,
// // //             entityKey,
// // //             " "
// // //           );
// // //           onChange(EditorState.forceSelection(newState, newState.getCurrentContent().getSelectionAfter()));
// // //         });
// // //       })
// // //       .catch((error) => {
// // //         console.error("Error uploading images: ", error);
// // //       });
// // //   };

// // //   const handleFileInput = (event) => {
// // //     const files = event.target.files;
// // //     handleImageUpload(files);
// // //   };

// // //   return (
// // //     <div>
// // //       <label
// // //         style={{
// // //           opacity: 0.9,
// // //           marginTop: 10,
// // //         }}
// // //       >
// // //         {label}
// // //       </label>
// // //       <div
// // //         className="editor"
// // //         style={{
// // //           minHeight: "200px",
// // //           backgroundColor: "#F4F4F4",
// // //           borderRadius: 15,
// // //           marginTop: 12,
// // //         }}
// // //         ref={editorContainerRef}
// // //       >
// // //         <Editor
// // //           editorClassName="editor-container"
// // //           editorState={state}
// // //           onEditorStateChange={(newState) => {
// // //             onChange(newState);
// // //           }}
// // //           toolbar={{
// // //             inline: { inDropdown: true },
// // //             list: { inDropdown: true },
// // //             textAlign: { inDropdown: true },
// // //             link: { inDropdown: true },
// // //             image: {
// // //               previewImage: true,
// // //               uploadCallback: uploadImageCallBack,
// // //               alt: { present: false, mandatory: false },
// // //             },
// // //           }}
// // //         />
// // //         <input
// // //           type="file"
// // //           multiple
// // //           onChange={handleFileInput}
// // //           style={{ marginTop: 10 }}
// // //         />
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default EditorComponent;

// // import React, { useRef } from "react";
// // import { Editor } from "react-draft-wysiwyg";
// // import { EditorState, AtomicBlockUtils } from "draft-js";
// // import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// // import axios from "axios";
// // import { endpoint } from "../../config/endpoint";

// // const EditorComponent = ({ label, state, onChange }) => {
// //   const editorContainerRef = useRef(null);

// //   const uploadImageCallBack = (file) => {
// //     return new Promise((resolve, reject) => {
// //       const formData = new FormData();
// //       formData.append("file", file);
// //       axios
// //         .post(`${endpoint}/v1/blog/upload-image`, formData, {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
// //           },
// //         })
// //         .then((response) => {
// //           resolve({ data: { link: response.data.imageUrl } });
// //         })
// //         .catch((error) => {
// //           reject(error);
// //         });
// //     });
// //   };

// //   const handleImageUpload = (files) => {
// //     const promises = [];
// //     for (let i = 0; i < files.length; i++) {
// //       promises.push(uploadImageCallBack(files[i]));
// //     }
// //     Promise.all(promises)
// //       .then((results) => {
// //         let newState = state;
// //         results.forEach((result) => {
// //           const contentState = newState.getCurrentContent();
// //           const contentStateWithEntity = contentState.createEntity(
// //             "IMAGE",
// //             "IMMUTABLE",
// //             { src: result.data.link }
// //           );
// //           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
// //           newState = AtomicBlockUtils.insertAtomicBlock(
// //             newState,
// //             entityKey,
// //             " "
// //           );
// //         });
// //         onChange(EditorState.forceSelection(newState, newState.getCurrentContent().getSelectionAfter()));
// //       })
// //       .catch((error) => {
// //         console.error("Error uploading images: ", error);
// //       });
// //   };

// //   const handleFileInput = (event) => {
// //     const files = event.target.files;
// //     handleImageUpload(files);
// //   };

// //   return (
// //     <div>
// //       <label
// //         style={{
// //           opacity: 0.9,
// //           marginTop: 10,
// //         }}
// //       >
// //         {label}
// //       </label>
// //       <div
// //         className="editor"
// //         style={{
// //           minHeight: "200px",
// //           backgroundColor: "#F4F4F4",
// //           borderRadius: 15,
// //           marginTop: 12,
// //         }}
// //         ref={editorContainerRef}
// //       >
// //         <Editor
// //           editorClassName="editor-container"
// //           editorState={state}
// //           onEditorStateChange={(newState) => {
// //             onChange(newState);
// //           }}
// //           toolbar={{
// //             inline: { inDropdown: true },
// //             list: { inDropdown: true },
// //             textAlign: { inDropdown: true },
// //             link: { inDropdown: true },
// //             image: {
// //               previewImage: true,
// //               uploadCallback: uploadImageCallBack,
// //               alt: { present: false, mandatory: false },
// //             },
// //           }}
// //         />
// //         <input
// //           type="file"
// //           multiple
// //           onChange={handleFileInput}
// //           style={{ marginTop: 10 }}
// //         />
// //       </div>
// //     </div>
// //   );
// // };

// // export default EditorComponent;

// import React, { useRef } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, AtomicBlockUtils } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import axios from "axios";
// import { endpoint } from "../../config/endpoint";

// const EditorComponent = ({ label, state, onChange }) => {
//   const editorContainerRef = useRef(null);

//   const uploadImageCallBack = (file) => {
//     return new Promise((resolve, reject) => {
//       const formData = new FormData();
//       formData.append("file", file);
//       axios
//         .post(`${endpoint}/v1/blog/upload-image`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
//           },
//         })
//         .then((response) => {
//           resolve({ data: { link: response.data.imageUrl } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   };

//   const handleImageUpload = (files) => {
//     const promises = [];
//     for (let i = 0; i < files.length; i++) {
//       promises.push(uploadImageCallBack(files[i]));
//     }
//     Promise.all(promises)
//       .then((results) => {
//         let newState = state;
//         results.forEach((result) => {
//           const contentState = newState.getCurrentContent();
//           const contentStateWithEntity = contentState.createEntity(
//             "IMAGE",
//             "IMMUTABLE",
//             { src: result.data.link }
//           );
//           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//           newState = AtomicBlockUtils.insertAtomicBlock(
//             newState,
//             entityKey,
//             " "
//           );
//         });
//         onChange(
//           EditorState.forceSelection(
//             newState,
//             newState.getCurrentContent().getSelectionAfter()
//           )
//         );
//       })
//       .catch((error) => {
//         console.error("Error uploading images: ", error);
//       });
//   };

//   const handleFileInput = (event) => {
//     const files = event.target.files;
//     handleImageUpload(files);
//   };

//   return (
//     <div>
//       <label
//         style={{
//           opacity: 0.9,
//           marginTop: 10,
//         }}
//       >
//         {label}
//       </label>
//       <div
//         className="editor"
//         style={{
//           minHeight: "200px",
//           backgroundColor: "#F4F4F4",
//           borderRadius: 15,
//           marginTop: 12,
//         }}
//         ref={editorContainerRef}
//       >
//         <Editor
//           editorClassName="editor-container"
//           editorState={state}
//           onEditorStateChange={(newState) => {
//             onChange(newState);
//           }}
//           toolbar={{
//             inline: { inDropdown: true },
//             list: { inDropdown: true },
//             textAlign: { inDropdown: true },
//             link: { inDropdown: true },
//             // Remove the default image upload option
//           }}
//         />
//         <input
//           type="file"
//           multiple
//           onChange={handleFileInput}
//           style={{ marginTop: 10 }}
//         />
//       </div>
//     </div>
//   );
// };

// export default EditorComponent;

// import React, { useRef } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, AtomicBlockUtils } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import axios from "axios";
// import { endpoint } from "../../config/endpoint";

// const EditorComponent = ({ label, state, onChange }) => {
//   const editorContainerRef = useRef(null);

//   const uploadImageCallBack = (file) => {
//     return new Promise((resolve, reject) => {
//       const formData = new FormData();
//       formData.append("file", file);
//       axios
//         .post(`${endpoint}/v1/blog/upload-image`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
//           },
//         })
//         .then((response) => {
//           resolve({ data: { link: response.data.imageUrl } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   };

//   const handleImageUpload = (files) => {
//     const promises = [];
//     for (let i = 0; i < files.length; i++) {
//       promises.push(uploadImageCallBack(files[i]));
//     }
//     Promise.all(promises)
//       .then((results) => {
//         let newState = state;
//         results.forEach((result) => {
//           const contentState = newState.getCurrentContent();
//           const contentStateWithEntity = contentState.createEntity(
//             "IMAGE",
//             "IMMUTABLE",
//             { src: result.data.link }
//           );
//           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//           newState = AtomicBlockUtils.insertAtomicBlock(
//             newState,
//             entityKey,
//             " "
//           );
//         });
//         onChange(EditorState.forceSelection(newState, newState.getCurrentContent().getSelectionAfter()));
//       })
//       .catch((error) => {
//         console.error("Error uploading images: ", error);
//       });
//   };

//   const handleFileInput = (event) => {
//     const files = event.target.files;
//     handleImageUpload(files);
//   };

//   const customImageUpload = () => {
//     document.getElementById("imageUploadInput").click();
//   };

//   return (
//     <div>
//       <label
//         style={{
//           opacity: 0.9,
//           marginTop: 10,
//         }}
//       >
//         {label}
//       </label>
//       <div
//         className="editor"
//         style={{
//           minHeight: "200px",
//           backgroundColor: "#F4F4F4",
//           borderRadius: 15,
//           marginTop: 12,
//         }}
//         ref={editorContainerRef}
//       >
//         <Editor
//           editorClassName="editor-container"
//           editorState={state}
//           onEditorStateChange={(newState) => {
//             onChange(newState);
//           }}
//           toolbar={{
//             inline: { inDropdown: true },
//             list: { inDropdown: true },
//             textAlign: { inDropdown: true },
//             link: { inDropdown: true },
//             image: {
//               icon: "image",
//               className: "rdw-option-wrapper",
//               component: (props) => {
//                 return (
//                   <div
//                     className="rdw-option-wrapper"
//                     onClick={customImageUpload}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <img src="/images.png"/>
//                   </div>
//                 );
//               },
//               popupClassName: undefined,
//               urlEnabled: false,
//               uploadEnabled: true,
//               alignmentEnabled: false,
//               uploadCallback: uploadImageCallBack,
//               previewImage: true,
//               inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//               alt: { present: false, mandatory: false },
//               defaultSize: {
//                 height: 'auto',
//                 width: 'auto',
//               },
//             },
//           }}
//         />
//         <input
//           type="file"
//           id="imageUploadInput"
//           multiple
//           onChange={handleFileInput}
//           style={{ display: "none" }}
//         />
//       </div>
//     </div>
//   );
// };

// export default EditorComponent;

// import React, { useRef } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, AtomicBlockUtils, Modifier } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import axios from "axios";
// import { endpoint } from "../../config/endpoint";

// const EditorComponent = ({ label, state, onChange }) => {
//   const editorContainerRef = useRef(null);

//   const uploadImageCallBack = (file) => {
//     return new Promise((resolve, reject) => {
//       const formData = new FormData();
//       formData.append("file", file);
//       axios
//         .post(`${endpoint}/v1/blog/upload-image`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
//           },
//         })
//         .then((response) => {
//           resolve({ data: { link: response.data.imageUrl } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   };

//   const handleImageUpload = (files) => {
//     const promises = [];
//     for (let i = 0; i < files.length; i++) {
//       promises.push(uploadImageCallBack(files[i]));
//     }
//     Promise.all(promises)
//       .then((results) => {
//         let newState = state;
//         results.forEach((result) => {
//           const contentState = newState.getCurrentContent();
//           const contentStateWithEntity = contentState.createEntity(
//             "IMAGE",
//             "IMMUTABLE",
//             { src: result.data.link }
//           );
//           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//           newState = AtomicBlockUtils.insertAtomicBlock(
//             newState,
//             entityKey,
//             " "
//           );
//         });
//         onChange(EditorState.forceSelection(newState, newState.getCurrentContent().getSelectionAfter()));
//       })
//       .catch((error) => {
//         console.error("Error uploading images: ", error);
//       });
//   };

//   const handleFileInput = (event) => {
//     const files = event.target.files;
//     handleImageUpload(files);
//   };

//   const customImageUpload = () => {
//     document.getElementById("imageUploadInput").click();
//   };

//   const blockRendererFn = (block) => {
//     if (block.getType() === "atomic") {
//       const contentState = state.getCurrentContent();
//       const entity = contentState.getEntity(block.getEntityAt(0));
//       const type = entity.getType();
//       if (type === "IMAGE") {
//         const { src } = entity.getData();
//         return {
//           component: ImageComponent,
//           editable: false,
//           props: {
//             src,
//           },
//         };
//       }
//     }
//     return null;
//   };

//   const ImageComponent = (props) => {
//     return (
//       <img
//         src={props.src}
//         alt="uploaded"
//         style={{ width: "100px", height: "100px" }} // Thumbnail size
//       />
//     );
//   };

//   return (
//     <div>
//       <label
//         style={{
//           opacity: 0.9,
//           marginTop: 10,
//         }}
//       >
//         {label}
//       </label>
//       <div
//         className="editor"
//         style={{
//           minHeight: "200px",
//           backgroundColor: "#F4F4F4",
//           borderRadius: 15,
//           marginTop: 12,
//         }}
//         ref={editorContainerRef}
//       >
//         <Editor
//           editorClassName="editor-container"
//           editorState={state}
//           onEditorStateChange={(newState) => {
//             onChange(newState);
//           }}
//           blockRendererFn={blockRendererFn}
//           toolbar={{
//             inline: { inDropdown: true },
//             list: { inDropdown: true },
//             textAlign: { inDropdown: true },
//             link: { inDropdown: true },
//             image: {
//               icon: "image",
//               className: "rdw-option-wrapper",
//               component: (props) => {
//                 return (
//                   <div
//                     className="rdw-option-wrapper"
//                     onClick={customImageUpload}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <img src="/images.png" alt="upload"/>
//                   </div>
//                 );
//               },
//               popupClassName: undefined,
//               urlEnabled: false,
//               uploadEnabled: true,
//               alignmentEnabled: false,
//               uploadCallback: uploadImageCallBack,
//               previewImage: true,
//               inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//               alt: { present: false, mandatory: false },
//               defaultSize: {
//                 height: 'auto',
//                 width: 'auto',
//               },
//             },
//           }}
//         />
//         <input
//           type="file"
//           id="imageUploadInput"
//           multiple
//           onChange={handleFileInput}
//           style={{ display: "none" }}
//           accept="image/*"
//         />
//       </div>
//     </div>
//   );
// };

// export default EditorComponent;

// import React, { useRef } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, AtomicBlockUtils } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import axios from "axios";
// import { endpoint } from "../../config/endpoint";

// const EditorComponent = ({ label, state, onChange }) => {
//   const editorContainerRef = useRef(null);

//   const uploadImageCallBack = (file) => {
//     return new Promise((resolve, reject) => {
//       const formData = new FormData();
//       formData.append("file", file);
//       axios
//         .post(`${endpoint}/v1/blog/upload-image`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("j-token")}`,
//           },
//         })
//         .then((response) => {
//           resolve({ data: { link: response.data.imageUrl } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   };

//   const handleImageUpload = (files) => {
//     const promises = [];
//     for (let i = 0; i < files.length; i++) {
//       promises.push(uploadImageCallBack(files[i]));
//     }
//     Promise.all(promises)
//       .then((results) => {
//         let newState = state;
//         results.forEach((result) => {
//           const contentState = newState.getCurrentContent();
//           const contentStateWithEntity = contentState.createEntity(
//             "IMAGE",
//             "IMMUTABLE",
//             { src: result.data.link }
//           );
//           const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//           newState = AtomicBlockUtils.insertAtomicBlock(
//             newState,
//             entityKey,
//             " "
//           );
//         });
//         onChange(EditorState.forceSelection(newState, newState.getCurrentContent().getSelectionAfter()));
//       })
//       .catch((error) => {
//         console.error("Error uploading images: ", error);
//       });
//   };

//   const handleFileInput = (event) => {
//     const files = event.target.files;
//     handleImageUpload(files);
//   };

//   const customImageUpload = () => {
//     document.getElementById("imageUploadInput").click();
//   };

//   const blockRendererFn = (block) => {
//     if (block.getType() === "atomic") {
//       const contentState = state.getCurrentContent();
//       const entity = contentState.getEntity(block.getEntityAt(0));
//       const type = entity.getType();
//       if (type === "IMAGE") {
//         return {
//           component: ImageComponent,
//           editable: false,
//           props: {
//             src: entity.getData().src,
//           },
//         };
//       }
//     }
//     return null;
//   };

//   const ImageComponent = ({ src }) => {
//     return (
//       <div>
//         <img
//           src={src}
//           alt="uploaded"
//           style={{ width: "100px", height: "100px" }} // Fixed size 100x100
//         />
//       </div>
//     );
//   };

//   return (
//     <div>
//       <label
//         style={{
//           opacity: 0.9,
//           marginTop: 10,
//         }}
//       >
//         {label}
//       </label>
//       <div
//         className="editor"
//         style={{
//           minHeight: "200px",
//           backgroundColor: "#F4F4F4",
//           borderRadius: 15,
//           marginTop: 12,
//         }}
//         ref={editorContainerRef}
//       >
//         <Editor
//           editorClassName="editor-container"
//           editorState={state}
//           onEditorStateChange={(newState) => {
//             onChange(newState);
//           }}
//           blockRendererFn={blockRendererFn}
//           toolbar={{
//             inline: { inDropdown: true },
//             list: { inDropdown: true },
//             textAlign: { inDropdown: true },
//             link: { inDropdown: true },
//             image: {
//               icon: "image",
//               className: "rdw-option-wrapper",
//               component: (props) => {
//                 return (
//                   <div
//                     className="rdw-option-wrapper"
//                     onClick={customImageUpload}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <img src="/images.png" alt="upload"/>
//                   </div>
//                 );
//               },
//               popupClassName: undefined,
//               urlEnabled: false,
//               uploadEnabled: true,
//               alignmentEnabled: false,
//               uploadCallback: uploadImageCallBack,
//               previewImage: true,
//               inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//               alt: { present: false, mandatory: false },
//               defaultSize: {
//                 height: 'auto',
//                 width: 'auto',
//               },
//             },
//           }}
//         />
//         <input
//           type="file"
//           id="imageUploadInput"
//           multiple
//           onChange={handleFileInput}
//           style={{ display: "none" }}
//           accept="image/*"
//         />
//       </div>
//     </div>
//   );
// };

// export default EditorComponent;
import React, { useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, AtomicBlockUtils, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
import Loader from "../Loader";
import toast from "react-hot-toast";

const EditorComponent = ({
  label,
  state,
  onChange,
  setMultiImageUpload,
  multiImageUpload,
}) => {
  const editorContainerRef = useRef(null);

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(`${endpoint}/v1/blog/upload-watermark-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        })
        .then((response) => {
          resolve({ data: { link: response.data.imageUrl } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleImageUpload = (files) => {
    const promises = [];
    setMultiImageUpload(true);
    for (let i = 0; i < files.length; i++) {
      promises.push(uploadImageCallBack(files[i]));
    }
    Promise.all(promises)
      .then((results) => {
        let newState = state;
        results.forEach((result) => {
          const contentState = newState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "IMMUTABLE",
            { src: result.data.link }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          newState = AtomicBlockUtils.insertAtomicBlock(
            newState,
            entityKey,
            " "
          );
        });
        onChange(
          EditorState.forceSelection(
            newState,
            newState.getCurrentContent().getSelectionAfter()
          )
        );
        setMultiImageUpload(false);
      })
      .catch((error) => {
        console.error("Error uploading images: ", error);
        setMultiImageUpload(false);
        toast.error("Error Uploading images");
      });
  };

  const handleFileInput = (event) => {
    const files = event.target.files;
    handleImageUpload(files);
  };

  const customImageUpload = () => {
    document.getElementById("imageUploadInput").click();
  };

  const blockRendererFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: MediaComponent,
        editable: false,
      };
    }
    return null;
  };

  return (
    <div>
      <label
        style={{
          opacity: 0.9,
          marginTop: 10,
        }}
      >
        {label}
      </label>
      <div
        className="editor"
        style={{
          minHeight: "200px",
          backgroundColor: "#F4F4F4",
          borderRadius: 15,
          marginTop: 12,
          position: "relative",
        }}
        ref={editorContainerRef}
      >
        {multiImageUpload && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
        <Editor
          editorClassName="editor-container"
          editorState={state}
          onEditorStateChange={(newState) => {
            onChange(newState);
          }}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            image: {
              icon: "image",
              className: "rdw-option-wrapper",
              component: (props) => {
                return (
                  <div
                    className="rdw-option-wrapper"
                    onClick={customImageUpload}
                    style={{ cursor: "pointer" }}
                    aria-disabled={multiImageUpload}
                  >
                    <img src="/images.png" alt="upload icon" />
                  </div>
                );
              },
              popupClassName: undefined,
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: false,
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: "auto",
                width: "auto",
              },
            },
          }}
          blockRendererFn={blockRendererFn}
        />
        <input
          type="file"
          id="imageUploadInput"
          multiple
          onChange={handleFileInput}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    </div>
  );
};

const MediaComponent = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  return (
    <img
      src={src}
      style={{
        height: "100px",
        width: "200px",
      }}
      alt="Uploaded"
    />
  );
};

export default EditorComponent;
