import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  addAdmin,
  deleteAdmin,
  getAdmins,
  updateAdminPassword,
} from "../../../redux/slices/users.slice";
import moment from "moment";
import Icon from "../../../components/Icon";
import cn from "classnames";
import Modal from "../../../components/Modal";
import Form from "../Table/form/Form";
import toast from "react-hot-toast";
const Table = () => {
  const dispatch = useDispatch();
  const { loading, users } = useSelector((state) => state.users);
  const { auth } = useSelector((state) => state.auth);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [addAdminLoading, setAddAdminLogin] = useState(false);
  const [editData, setEditData] = useState(null);
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
  });
  const getAdminsData = (filter) => {
    dispatch(
      getAdmins({
        payload: "",
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getAdminsData();
  }, [dispatch]);

  const addNewAdmin = () => {
    if (!state.name) {
      toast.error("Please enter name");
      return false;
    }
    if (!state.email) {
      toast.error("Please enter email");
      return false;
    }
    if (!state.password) {
      toast.error("Please enter password");
      return false;
    } else {
      setAddAdminLogin(true);
      dispatch(
        addAdmin({
          payload: {
            name: state.name,
            email: state.email,
            password: state.password,
            role: auth?.role,
          },
          callback: (data) => {
            if (data?.message) {
              setAddAdminLogin(false);
              toast.success("Admin created successfully");
              setState({
                name: "",
                email: "",
                password: "",
              });
              getAdminsData();
              setAddAdminModal(false);
            } else {
              setAddAdminLogin(false);
              toast.error(data?.error);
            }
          },
        })
      );
    }
  };
  const deleteSingleAdmin = (id) => {
    dispatch(
      deleteAdmin({
        payload: {
          id: id,
        },
        callback: (data) => {
          if (data.status == 200) {
            toast.success("Admin deleted successfully");
            getAdminsData();
          } else {
            toast.error(data.error);
          }
        },
      })
    );
  };
  const changeAdminPassword = (data) => {
    setEditData(data);
    setAddAdminModal(true);
  };
  const updateSingleAdminPassword = () => {
    if (!state.password) {
      toast.error("Please enter New password");
      return false;
    } else {
      setAddAdminLogin(true);
      dispatch(
        updateAdminPassword({
          payload: {
            password: state.password,
            userId: editData?.id,
            role: auth?.role,
          },
          callback: (data) => {
            if (data.status == 200) {
              setAddAdminLogin(false);
              toast.success("Password updated successfully");
              setAddAdminModal(false);
              setState({
                name: "",
                email: "",
                password: "",
              });
              getAdminsData();
              setEditData(null);
            } else {
              setAddAdminLogin(false);
              toast.error(data.error);
            }
          },
        })
      );
    }
  };
  return (
    <div>
      <Modal
        visible={addAdminModal}
        onClose={() => {
          setEditData(null);
          setAddAdminModal(false);
        }}
        title={editData ? "Update Password" : "Add Admin"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            loading={addAdminLoading}
            onClick={editData ? updateSingleAdminPassword : addNewAdmin}
            state={state}
            setState={setState}
            editData={editData}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setAddAdminModal(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Name
            </div>
            <div className={styles.col} data-label="Title">
              Email
            </div>
            <div className={styles.col} data-label="Title">
              Role
            </div>

            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : users?.length > 0 ? (
            users?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="name">
                  <p>{x?.name}</p>
                </div>
                <div className={styles.col} data-label="email">
                  <p>{x?.email}</p>
                </div>
                <div className={styles.col} data-label="role">
                  <p>{x?.role}</p>
                </div>
                <div className={styles.col} data-label="createdAt">
                  <p> {moment(x?.createdAt).format("MM/DD/YYYY")}</p>
                </div>
                <div
                  className={styles.col}
                  style={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <div onClick={() => deleteSingleAdmin(x?.id)}>
                    <Icon
                      name={"trash"}
                      size="24"
                      className={styles.iconColorRed}
                    />
                  </div>
                  <div onClick={() => changeAdminPassword(x)}>
                    <Icon
                      name={"edit"}
                      size="24"
                      className={styles.iconColor}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
