// import React from "react";
// import cn from "classnames";
// import styles from "./File.module.sass";
// import Icon from "../Icon";
// import Tooltip from "../Tooltip";
// import Loader from "../Loader";

// const File = ({
//   className,
//   label,
//   tooltip,
//   value,
//   title,
//   videoLoading,
//   onChange,
// }) => {
//   return (
//     <div className={cn(styles.file, className)} aria-disabled={true}>
//       {label && (
//         <div className={styles.label}>
//           {label}{" "}
//           {tooltip && (
//             <Tooltip
//               className={styles.tooltip}
//               title={tooltip}
//               icon="info"
//               place="right"
//             />
//           )}
//         </div>
//       )}
//       <div className={styles.wrap}>
//         <input
//           className={styles.input}
//           type="file"
//           // value={value}
//           onChange={onChange}
//           disabled={videoLoading}
//         />
//         <div className={styles.box}>
//           {videoLoading ? (
//             <Loader color={"#ff3c00"} />
//           ) : (
//             <Icon name="upload" size="24" />
//           )}
//           <span
//             style={{
//               marginLeft: videoLoading ? 10 : 0,
//             }}
//           >
//             {videoLoading
//               ? "Uploading Please wait.This may take sometime..."
//               : title}
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default File;

import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import Loader from "../Loader";

const messages = [
  "Uploading, please wait...",
  "This may take some time...",
  "Almost there...",
  "Just a bit longer...",
  "Thank you for your patience.",
];

const File = ({
  className,
  label,
  tooltip,
  value,
  title,
  videoLoading,
  onChange,
  accept,
}) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    let interval;
    if (videoLoading) {
      interval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 20000); // 10 seconds
    } else {
      setMessageIndex(0); // Reset message index when not loading
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount or when videoLoading becomes false
  }, [videoLoading]);

  return (
    <div className={cn(styles.file, className)} aria-disabled={true}>
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input
          className={styles.input}
          type="file"
          // value={value}
          onChange={onChange}
          disabled={videoLoading}
          accept={accept}
        />
        <div className={styles.box}>
          {videoLoading ? (
            <Loader color={"#ff3c00"} />
          ) : (
            <Icon name="upload" size="24" />
          )}
          <span
            style={{
              marginLeft: videoLoading ? 10 : 0,
            }}
          >
            {videoLoading ? messages[messageIndex] : title}
          </span>
        </div>
      </div>
    </div>
  );
};

export default File;
