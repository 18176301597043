import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  users: [],
  loading: false,
};

export const getAdmins = createAsyncThunk(
  "users/getAdmins",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      const response = await axios.get(
        `${endpoint}/v1/admin/get-all-admins`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addAdmin = createAsyncThunk(
  "users/addAdmin",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/admin/signup`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteAdmin = createAsyncThunk(
  "users/deleteAdmin",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/admin/change-admin-status`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateAdminPassword = createAsyncThunk(
  "users/adminPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/admin/change-other-admin-password`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdmins.fulfilled, (state, { payload }) => {
      state.users = payload?.data;
      state.loading = false;
    });
    builder.addCase(getAdmins.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = usersSlice.actions;
export default usersSlice.reducer;
