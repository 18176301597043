import React, { useState } from "react";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";

export default function Form({
  loading,
  onClick,
  editData,
  updateInfluencerData,
  name,
  description,
  setName,
  setDescription,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
    
        <>
          <TextInput
            label="Name"
            className={""}
            name="text"
            type="text"
            placeholder="Name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextInput
            label="Description"
            className={""}
            name="text"
            type="text"
            placeholder="Description"
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </>
      
      <button
        onClick={editData ? updateInfluencerData : onClick}
        disabled={false}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          cursor: loading ? "no-drop" : "pointer",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : editData ? (
          "Update Influencer"
        ) : (
          "Add Influencer"
        )}
      </button>
    </div>
  );
}
