import React from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";
import { Oval } from "react-loader-spinner";

const Loader = ({ height, width, color }) => {
  return (
    // <div
    //   className={cn(styles.loader, className, {
    //     [styles.loaderWhite]: white,
    //   })}
    // ></div>

    <Oval
      visible={true}
      height={height || "35"}
      width={width || "35"}
      color={color || "#FF3C00"}
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export default Loader;
