import React, { useEffect, useState } from "react";
import Switch from "../../components/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
  changeWebsiteMode,
  getWebsiteMode,
} from "../../redux/slices/auth.slice";
import Loader from "../../components/Loader";

export default function MaintenanceMode() {
  const { loading, maintenanceMode } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const getMaintenanceMode = () => {
    dispatch(
      getWebsiteMode({
        payload: "",
        callback: (data) => {
          if (data.status == 200) {
            console.log(data.data.id)
            setId(data.data.id);
          }
        },
      })
    );
  };

  useEffect(() => {
    getMaintenanceMode();
  }, []);
  useEffect(() => {
    setChecked(maintenanceMode);
  }, [maintenanceMode]);
  const changeMaintenanceMode = (value) => {
    console.log(value)
    setChecked(value);
    try {
      setToggleLoading(true);
      dispatch(
        changeWebsiteMode({
          payload: {
            id,
            mode: value ? "ACTIVE" : "UNACTIVE",
          },
          callback: (data) => {
            if (data.status == 200) {
              setToggleLoading(false);
            }
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        height: "100px",
        padding: "20px",
      }}
    >
      <p>Change to Maintenance Mode</p>
      {loading ? (
        <Loader />
      ) : (
        <Switch
          value={checked}
          disable={toggleLoading}
          onChange={(e) => changeMaintenanceMode(e.target.checked)} // Update local checked state on change
        />
      )}
    </div>
  );
}
