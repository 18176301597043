// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipGlodal_tooltipWpapper__m2Z-I {\n  padding: 3px 12px !important;\n  border-radius: 4px;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;\n  font-size: 12px !important;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/components/TooltipGlodal/TooltipGlodal.module.sass"],"names":[],"mappings":"AAEA;EACI,4BAAA;EACA,kBAAA;EACA,qEAAA;EACA,0BAAA;EACA,gBAAA;AADJ","sourcesContent":["@import ../../styles/helpers\n\n.tooltipWpapper\n    padding: 3px 12px !important\n    border-radius: 4px\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000 \n    font-size: 12px !important\n    font-weight: 600 "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWpapper": "TooltipGlodal_tooltipWpapper__m2Z-I"
};
export default ___CSS_LOADER_EXPORT___;
