


import React from "react";
import Table from "./Table";

export default function FeaturedVideo() {
  return (
    <div>
      <Table />
    </div>
  );
}
