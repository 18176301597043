import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useDispatch } from "react-redux";
import { adminSignin } from "../../redux/slices/auth.slice";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";

const SignIn = () => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const ctaFieldChangeHandler = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      if (!state.email || !state.password) {
        alert("Please fill all the fields");
      } else {
        setLoading(true);
        dispatch(
          adminSignin({
            payload: state,
            callback: (data) => {
              if (data?.data) {
                localStorage.setItem("j-token", data?.data?.token);
                navigate("/");
                toast.success("Welcome Back!");
                setLoading(false);
              } else {
                toast.error(data?.message);
                setLoading(false);
              }
            },
          })
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo.svg"
            alt="Jewasity-logo"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.head}></div>
        <form onSubmit={login}>
          <div className={styles.body}>
            <div className={styles.subtitle}>Continue with email address</div>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              value={state.email}
              onChange={(e) => ctaFieldChangeHandler("email", e.target.value)}
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              value={state.password}
              onChange={(e) =>
                ctaFieldChangeHandler("password", e.target.value)
              }
            />
            <button
              type="submit"
              style={{
                backgroundColor: "#ff3c00",
                width: "100%",
                height: 50,
                borderRadius: 30,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? <Loader white={"white"} /> : "Sign in"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
