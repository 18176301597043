import React, { useState } from "react";
import TextInput from "../../components/TextInput";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/slices/auth.slice";
import toast from "react-hot-toast";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const changePasswordHandler = () => {
    try {
      if (!state.password) {
        toast.error("Please enter the current password");
        return false;
      }
      if (!state.newPassword) {
        toast.error("Please enter a new password");
        return false;
      }
      if (!state.confirmPassword) {
        toast.error("Please enter the confirm password");
        return false;
      }
      if (state.newPassword !== state.confirmPassword) {
        toast.error("New password and confirm password do not match");
        return false;
      }

      setLoading(true);
      dispatch(
        changePassword({
          payload: state,
          callback: (data) => {
            setLoading(false);
            if (data?.status === 200) {
                setLoading(false)
              toast.success("Password changed successfully");
              setState({
                password: "",
                newPassword: "",
                confirmPassword: "",
              })
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 15,
        display: "flex",
        flexDirection: "column",
        gap: 15,
      }}
    >
      <TextInput
        label="Current Password"
        className={""}
        name="text"
        type="text"
        placeholder="Enter Current Password"
        required
        value={state?.password}
        onChange={(e) => {
          stateChangeHandler("password", e.target.value);
        }}
      />
      <TextInput
        label="Password"
        className={""}
        name="text"
        type="text"
        placeholder="Password"
        required
        value={state?.newPassword}
        onChange={(e) => {
          stateChangeHandler("newPassword", e.target.value);
        }}
      />
      <TextInput
        label="Confirm Password"
        className={""}
        name="text"
        type="text"
        placeholder="Enter Confirm Password"
        required
        value={state?.confirmPassword}
        onChange={(e) => {
          stateChangeHandler("confirmPassword", e.target.value);
        }}
      />
      <button
        onClick={changePasswordHandler}
        disabled={loading}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
          cursor: loading ? "no-drop" : "pointer",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : (
          "Change Password"
        )}
      </button>
    </div>
  );
}
