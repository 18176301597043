// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip_tooltip__YRnOd {\n  display: inline-block;\n  margin-left: 4px;\n  cursor: pointer;\n}\n.Tooltip_tooltip__YRnOd .Tooltip_tooltipWpapper__BxiTq {\n  padding: 3px 12px;\n  border-radius: 4px;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;\n  font-size: 12px;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.module.sass"],"names":[],"mappings":"AAEA;EACI,qBAAA;EACA,gBAAA;EACA,eAAA;AADJ;AAEI;EACI,iBAAA;EACA,kBAAA;EACA,qEAAA;EACA,eAAA;EACA,gBAAA;AAAR","sourcesContent":["@import ../../styles/helpers\n\n.tooltip\n    display: inline-block\n    margin-left: 4px\n    cursor: pointer\n    .tooltipWpapper\n        padding: 3px 12px\n        border-radius: 4px\n        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000\n        font-size: 12px\n        font-weight: 600"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Tooltip_tooltip__YRnOd",
	"tooltipWpapper": "Tooltip_tooltipWpapper__BxiTq"
};
export default ___CSS_LOADER_EXPORT___;
