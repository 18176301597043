import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getVideos, verifyVideo } from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import { addBlog, uploadVideo } from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import {
  addCategory,
  getCategories,
  updateCategory,
} from "../../../redux/slices/category.slice";
import ReactPaginate from "react-paginate";

const Table = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { categories, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.category
  );

  //
  const [addLoading, setAddLoading] = useState(false);
  const [title, setTitle] = useState("");
  const getCategoryData =  (currentPage) => {
    dispatch(
      getCategories({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };
  useEffect(() => {
    getCategoryData(currentPage);
  }, [dispatch,currentPage]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  const addCategoryHandler = () => {
    try {
      if (!title) {
        toast.error("Title is required");
        return false;
      }

      setAddLoading(true);
      dispatch(
        addCategory({
          payload: {
            title: title,
          },
          callback: (data) => {
            setAddLoading(false);
            if (data?.status === 200) {
              getCategoryData(currentPage);
              setTitle("");
              setVisible(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setAddLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCategoryHandler = (item) => {
    try {
      setAddLoading(true);
      dispatch(
        updateCategory({
          payload: {
            title: item?.title,
            status: "UNACTIVE",
            id: item?.id,
          },
          callback: (data) => {
            setAddLoading(false);
            if (data?.status === 200) {
              getCategoryData(currentPage);
              setVisible(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setAddLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title="Details"
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            setTitle={setTitle}
            title={title}
            loading={addLoading}
            addCategory={addCategoryHandler}
          />
        </div>
      </Modal>

      {/* <Modal visible={addBlogVisible} onClose={blogModalClose} title="Add Blog">
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            state={state}
            stateChangeHandler={stateChangeHandler}
            handleEditorStateChange={handleEditorStateChange}
            handleFileChange={handleFileChange}
            videoLoading={videoLoading}
            handleImageFileChange={handleImageFileChange}
            uploadBlog={uploadBlog}
            blogUploadLoading={blogUploadLoading}
            description={description}
          />
        </div>
      </Modal>  */}

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Status">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : categories?.length > 0 ? (
            categories?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p>{x?.title}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p> {moment(x?.createdAt).format("MM/DD/YYYY")}</p>
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => updateCategoryHandler(x)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}  // Highlight active tab
      />
    </div>
  );
};

export default Table;
