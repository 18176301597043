import React, { useState } from "react";

import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import {
  uploadVideo,
  videoThumbnail,
} from "../../../../redux/slices/blog.slice";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Loader from "../../../../components/Loader";
import { addMusic } from "../../../../redux/slices/music.slice";

export default function Form({
  handleReelFileChange,
  videoLoading,
  featuredVideoLoading,
  uploadFeaturedVideo,
  stateChangeHandler,
  state,
  updateMusicVideo,
  isEdit,
  handleReelImageChange,
  imageLoading,
  loading,
  addAds
}) {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 24,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <TextInput
        label="Ad Link"
        className={""}
        name="link"
        type="text"
        placeholder="Link"
        required
        value={state?.link}
        onChange={(e) => {
          stateChangeHandler("link", e.target.value);
        }}
      />

      {!isEdit && (
        <File
          title={
            state?.imageUrl
              ? "Image Uploaded"
              : state?.imageFile?.name
              ? state?.imageFile?.name?.slice(0, 6) + "..."
              : "Image"
          }
          videoLoading={imageLoading}
          onChange={handleReelImageChange}
          label={"Ad Image (Optional)"}
          accept={"image/*"}
        />
      )}
      {!isEdit && (
        <File
          title={
            state?.videoUrl
              ? "Video Uploaded"
              : state?.videoFile?.name
              ? state?.videoFile?.name?.slice(0, 6) + "..."
              : "Video"
          }
          videoLoading={videoLoading}
          onChange={handleReelFileChange}
          label={"Ad Video"}
          accept={"video/*"}
        />
      )}
      <button
        onClick={isEdit ? updateMusicVideo : addAds}
        disabled={videoLoading || loading || imageLoading}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          // padding: 10,
          height: 40,
          cursor:
            videoLoading || loading || imageLoading ? "no-drop" : "pointer",
          // alignSelf: "flex-start",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : isEdit ? (
          " Edit Ad"
        ) : (
          " Add Ad"
        )}
      </button>
    </div>
  );
}
