import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  tags: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const searchTags = createAsyncThunk(
  "tags/searchTags",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/tags/search-tags?name=${data?.payload}
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getTags = createAsyncThunk(
  "tags/getTags",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/tags?page=${data.payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addTag = createAsyncThunk(
  "tags/addTags",
  async (data, thunkAPI) => {
    try {
        console.log(data)
      const response = await axios.post(
        `${endpoint}/v1/tags/add-tag`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteTag = createAsyncThunk(
  "tags/deleteTag",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${endpoint}/v1/tags/delete-tag/${data?.payload?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateTag = createAsyncThunk(
  "tags/updateTag",
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${endpoint}/v1/tags/update-tag/${data?.payload?.id}`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const tagsSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.tags = payload?.data;
      state.loading = false;
      state.page = payload?.page;
      state.pageSize = payload?.pageSize;
      state.totalPages = payload?.totalPages;
    });
    builder.addCase(getTags.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(searchTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchTags.fulfilled, (state, { payload }) => {
      state.tags = payload?.data;
      state.loading = false;
    });
    builder.addCase(searchTags.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = tagsSlice.actions;
export default tagsSlice.reducer;
