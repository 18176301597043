import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideos,
  searchVideo,
  updateManyVideo,
  updateVideo,
  uploadSharpImage,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlog,
  addBlogImage,
  getBlogs,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import cn from "classnames";
import SearchForm from "../../../components/Form";
import ReactPaginate from "react-paginate";
const Table = () => {
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { videos, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.video
  );

  //
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [filterVisible, setFilterVisible] = useState(false);
  const [details, setDetails] = useState({});
  const [videoLoading, setVideoLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [reelImageLoading, setReelImageLoading] = useState(false);
  const [blogUploadLoading, setBlogUploadLoading] = useState(false);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [edit, setEdit] = useState(null);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [state, setState] = useState({
    title: edit ? edit?.title : "",
    video: "",
    thumbnailImage: null,
    reelUrl: "",
    thumbnailUrl: "",
    scheduleAt: "",
    selectedSchedule: "",
    isBreaking: false,
    customDate: edit ? edit?.createdAt : "",
    selectedCustomDate: edit ? edit?.createdAt : "",
    watermarkVideoUrl: "",
    watermark: false,
    reelImage: null,
    reelImageUrl: "",
  });
  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleImageChange = (e) => {
    // setImageLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    stateChangeHandler("thumbnailUrl", file);
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("type", "video");
    // try {
    //   dispatch(
    //     uploadSharpImage({
    //       payload: formData,
    //       callback: (data) => {
    //         if (data?.status === 200) {
    //           stateChangeHandler("thumbnailUrl", data?.imageUrl);
    //           setImageLoading(false);
    //         }
    //       },
    //     })
    //   );
    // } catch (error) {
    //   console.error("Error handling file change:", error);
    //   toast.error("An error occurred while handling the file change");
    //   setImageLoading(false);
    // }
  };
  const handleReelImageChange = (e) => {
    // setReelImageLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    stateChangeHandler("reelImageUrl", file);
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("type", "video");
    // try {
    //   dispatch(
    //     addBlogImage({
    //       payload: formData,
    //       callback: (data) => {
    //         if (data?.status === 200) {
    //           dispatch(
    //             uploadSharpImage({
    //               payload: formData,
    //               callback: (dataInner) => {
    //                 if (dataInner?.status === 200) {
    //                   stateChangeHandler("thumbnailUrl", dataInner?.imageUrl);
    //                   stateChangeHandler("reelImageUrl", data?.imageUrl);
    //                   setReelImageLoading(false);
    //                 }
    //               },
    //             })
    //           );
    //         }
    //       },
    //     })
    //   );
    // } catch (error) {
    //   console.error("Error handling file change:", error);
    //   toast.error("An error occurred while handling the file change");
    //   setReelImageLoading(false);
    // }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    stateChangeHandler("reelUrl", file);
  };
  // const handleFileChange = (e) => {
  //   try {
  //     const formData = new FormData();
  //     const file = e.target.files[0];
  //     if (!file) {
  //       toast.error("No file selected");
  //       return false;
  //     }
  //     setVideoLoading(true);

  //     console.log(file);
  //     if (!file) {
  //       toast.error("Please select video");
  //     } else {
  //       formData.append("file", file);
  //       formData.append("type", "video");
  //       if (!state?.thumbnailImage) {
  //         dispatch(
  //           uploadVideo({
  //             payload: formData,
  //             callback: (data) => {
  //               if (data?.status === 200) {
  //                 dispatch(
  //                   videoThumbnail({
  //                     payload: formData,
  //                     callback: (datainner) => {
  //                       if (datainner?.status === 200) {
  //                         stateChangeHandler("reelUrl", data?.videoUrl);
  //                         stateChangeHandler(
  //                           "watermarkVideoUrl",
  //                           data?.watermarkVideoUrl
  //                         );
  //                         stateChangeHandler(
  //                           "thumbnailUrl",
  //                           datainner?.thumbnailUrl
  //                         );
  //                         setVideoLoading(false);
  //                       } else {
  //                         toast.error(
  //                           "Something went wrong while fetching thumbnail"
  //                         );
  //                         setVideoLoading(false);
  //                       }
  //                     },
  //                   })
  //                 );
  //               } else {
  //                 toast.error("Something went wrong while uploading Reel");
  //                 setVideoLoading(false);
  //               }
  //             },
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           uploadVideo({
  //             payload: formData,
  //             callback: (data) => {
  //               if (data?.status === 200) {
  //                 stateChangeHandler("reelUrl", data?.videoUrl);
  //                 stateChangeHandler(
  //                   "watermarkVideoUrl",
  //                   data?.watermarkVideoUrl
  //                 );
  //                 setVideoLoading(false);
  //               } else {
  //                 toast.error("Something went wrong while uploading Reel");
  //                 setVideoLoading(false);
  //               }
  //             },
  //           })
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error handling file change:", error);
  //     toast.error("An error occurred while handling the file change");
  //     setVideoLoading(false);
  //   }
  // };

  const uploadVideoWithBlog = () => {
    const formData = new FormData();
    if (!state?.title) {
      toast.error("Title is required");
      return;
    }
    if (!state?.reelUrl && !state?.reelImageUrl) {
      toast.error("Either Reel Video or Image is required");
      return false;
    }
    if (state?.reelUrl && state?.reelImageUrl) {
      toast.error("Reel Video and Reel Image cannot be upload at once.");
      return;
    } else {
      try {
        setBlogUploadLoading(true);
        let data;
        if (state.scheduleAt) {
          formData.append("title", state?.title || "");
          formData.append("description", ""); // Empty description as per your initial data
          formData.append("scheduledAt", state?.scheduleAt || "");
          formData.append("isBreaking", state?.isBreaking || "");
          formData.append("waterMarkVideoUrl", state?.watermarkVideoUrl || "");
          if (state.customDate) {
            formData.append("customDate", state?.customDate);
          }

          if (state.reelUrl) {
            formData.append("videoUrl", state.reelUrl);
          }

          if (state.reelImageUrl) {
            formData.append("reelImageUrl", state.reelImageUrl);
          }

          if (state.thumbnailUrl) {
            formData.append("thumbnailUrl", state.thumbnailUrl);
          }
        } else {
          formData.append("title", state?.title || "");
          formData.append("description", ""); // Empty description as per your initial data
          formData.append("isBreaking", state?.isBreaking || "");
          formData.append("waterMarkVideoUrl", state?.watermarkVideoUrl || "");
          if (state.customDate) {
            formData.append("customDate", state?.customDate);
          }

          if (state.reelUrl) {
            formData.append("videoUrl", state.reelUrl);
          }

          if (state.reelImageUrl) {
            formData.append("reelImageUrl", state.reelImageUrl);
          }

          if (state.thumbnailUrl) {
            formData.append("thumbnailUrl", state.thumbnailUrl);
          }
        }

        dispatch(
          addVideo({
            payload: formData,
            callback: (data) => {
              setBlogUploadLoading(false);
              if (data?.status === 200) {
                getVideosData("true");
                setAddVideoModal(false);
                setState({
                  title: "",
                  reelUrl: "",
                  thumbnailUrl: "",
                  scheduleAt: "",
                  watermark: false,
                  watermarkVideoUrl: "",
                });
                toast.success(data?.message);
              } else {
                toast.error(data?.message);
              }
            },
          })
        );
      } catch (error) {
        setBlogUploadLoading(false);
        toast.error("Something went wrong");
      }
    }
  };
  const updateVideoTitle = () => {
    try {
      if (!state.title) {
        toast.error("Title is required");
        return;
      } else {
        setBlogUploadLoading(true);
        dispatch(
          updateVideo({
            payload: {
              videoId: edit?.id,
              title: state.title,
            },
            callback: (data) => {
              if (data?.status === 200) {
                getVideosData("true");
                toast.success("Updated Successfully");
                setBlogUploadLoading(false);
                setEditModalVisible(false);
              } else {
                toast.error("Something went wrong");
                setBlogUploadLoading(false);
              }
            },
          })
        );
      }
    } catch (error) {
      toast.error("Something went wrong");
      setBlogUploadLoading(false);
    }
  };
  const ctaDetailsHandler = (data) => {
    setVisible(true);
    setDetails(data);
  };
  const ctaEditHandler = (data) => {
    setEdit(data);
    stateChangeHandler("title", data?.title);
    setEditModalVisible(true);
  };
  const getVideosData = (filter, page) => {
    dispatch(
      getVideos({
        payload: { filter, page },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };
  const ctaFilterHandler = (filter) => {
    getVideosData(filter, currentPage);
    setFilterVisible(false);
  };

  const ctaChangeStatus = (item, status) => {
    try {
      setStatusId(item?.id);
      setStatusLoading(true); // Set loading to true before starting the dispatch

      dispatch(
        verifyVideo({
          payload: {
            id: item?.id,
            status: status,
          },
          callback: (data) => {
            if (data?.updatedVideo) {
              toast.success("Updated Successfully");
              getVideosData("true", currentPage);
            } else {
              toast.error("Something went wrong"); // Use toast.error for errors
            }
            setStatusId("");
            setStatusLoading(false); // Set loading to false after the callback finishes
          },
        })
      );
    } catch (error) {
      toast.error("Failed to update status");
      setStatusLoading(false); // Set loading to false if an error occurs
    }
  };
  //pagination

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  //
  useEffect(() => {
    getVideosData("true", currentPage);
  }, [dispatch, currentPage]);

  //get blogs

  const getAllBlogsName = () => {
    dispatch(
      getBlogs({
        payload: "",
        callback: (data) => {
          if (data?.status === 200) {
            const list = data?.data?.map((item) => {
              return {
                id: item?.id,
                title: item?.title,
              };
            });
            setBlogList(list);
          }
        },
      })
    );
  };
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const selectedItemHandler = (x) => {
    setSelectedVideos((prevBlogs) =>
      prevBlogs.includes(x?.id)
        ? prevBlogs.filter(
            (vidId) => vidId?.toLowerCase() !== x?.id?.toLowerCase()
          )
        : [...prevBlogs, x?.id]
    );
  };
  const deleteVideos = () => {
    setDeleteLoading(true);
    dispatch(
      updateManyVideo({
        payload: {
          ids: selectedVideos,
        },
        callback: (data) => {
          if (data.status === 200) {
            toast.success("Deleted Successfully");
            setDeleteLoading(false);
            dispatch(
              getVideos({
                payload: {
                  filter: "true",
                },
              })
            );
          }
        },
      })
    );
  };
  useEffect(() => {
    getAllBlogsName();
  }, []);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getVideos({
            payload: {
              filter: "true",
            },
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchVideo({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title="Details"
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <div className={styles.detailContainer}>
            <div className={styles.detailContainerHeading}>Title:</div>
            <div className={styles.detailContainerText}>{details?.title}</div>
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.detailContainerHeading}>Description:</div>
            <div className={styles.detailContainerText}>
              {details?.description}
            </div>
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.detailContainerHeading}>Video Url:</div>
            <div className={styles.detailContainerText}>
              <a
                href={details.videoUrl}
                target="_blank"
                style={{
                  color: "#ff3c00",
                  borderBottom: "1px solid #ff3c00",
                }}
                rel="noopener noreferrer"
              >
                Video_URL
              </a>
            </div>
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.detailContainerHeading}>Thumbnail Url:</div>
            <div className={styles.detailContainerText}>
              {" "}
              <a
                href={details.thumbnailUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#ff3c00",
                  borderBottom: "1px solid #ff3c00",
                }}
              >
                Thumbnail_URL
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={edit ? editModalVisible : addVideoModal}
        onClose={
          edit
            ? () => {
                setEdit(null);
                setState({
                  title: "",
                  reelUrl: "",
                  thumbnailUrl: "",
                  scheduleAt: "",
                });
                setEditModalVisible(false);
              }
            : () => {
                setEdit(null);
                setState({
                  title: "",
                  reelUrl: "",
                  thumbnailUrl: "",
                  scheduleAt: "",
                });
                setAddVideoModal(false);
              }
        }
        title={edit ? "Edit Video" : "Add Video"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            state={state}
            stateChangeHandler={stateChangeHandler}
            handleFileChange={handleFileChange}
            videoLoading={videoLoading}
            options={blogList}
            uploadVideo={uploadVideoWithBlog}
            videoUploadLoading={blogUploadLoading}
            handleImageChange={handleImageChange}
            imageLoading={imageLoading}
            editData={edit}
            updateVideoTitle={updateVideoTitle}
            handleReelImageChange={handleReelImageChange}
            reelImageLoading={reelImageLoading}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Video"
          type="text"
          name="search"
          icon="search"
        />
        <Filters
          title={"Status Filter"}
          visible={filterVisible}
          setVisible={setFilterVisible}
        >
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("true")}
          >
            <Icon name="check" size="20" />
            <p>Verified</p>
          </div>
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("false")}
          >
            <Icon name="clock" size="20" />
            <p>Pending</p>
          </div>
        </Filters>
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={
            edit
              ? () => {
                  setEdit(null);
                  setEditModalVisible(false);
                }
              : () => {
                  setEdit(null);
                  setAddVideoModal(true);
                }
          }
        >
          <Icon name="plus" size="18" />
        </button>
        {selectedVideos?.length > 0 &&
          (deleteLoading ? (
            <Loader height={20} width={20} color="#FF3C00" />
          ) : (
            <button
              className={cn("button-square-stroke button-small", styles.head)}
              onClick={deleteVideos}
            >
              <Icon name="trash" size="20" />
            </button>
          ))}
      </div>

      <div
        className={styles.wrapper}
        style={{
          overflow: "scroll",
        }}
      >
        <div className={styles.table} disable={deleteLoading}>
          <div
            className={styles.rowHeading}
            style={{
              width: "100%",
            }}
          >
            <div className={styles.col} data-label="Title"></div>
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Video">
              Video
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Thumbnail
            </div>
            <div className={styles.col} data-label="Status">
              Status
            </div>
            {/* <div className={styles.col} data-label="Featured">
              Sponsored
            </div> */}
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : videos?.length > 0 ? (
            videos?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="checkbox">
                  <Checkbox
                    checked={selectedVideos.includes(x?.id)}
                    onChange={(e) => selectedItemHandler(x)}
                  />
                </div>
                <div className={styles.col} data-label="Title">
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {x?.title?.length > 100
                      ? x?.title?.slice(0, 100) + "..."
                      : x?.title}
                  </p>
                </div>
                <div className={styles.col} data-label="Video">
                  <a
                    href={x?.videoUrl || x?.reelImageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    Video_URL
                  </a>
                </div>
                <div className={styles.col} data-label="Thumbnail">
                  <a
                    href={x?.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    <img src={x?.thumbnailUrl} height={140} width={160} />
                  </a>
                </div>
                <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.verified ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.verified ? "Verified" : "Pending"}
                  </span>
                </div>
                {/* <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.featured ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.featured ? "Yes" : "No"}
                  </span>
                </div> */}
                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {statusLoading && statusId == x?.id ? (
                      <Loader height={20} width={20} color={"#ff3c00"} />
                    ) : x?.verified === true ? (
                      <div onClick={() => ctaChangeStatus(x, "false")}>
                        <Icon
                          name={"trash"}
                          size="24"
                          className={styles.iconColorRed}
                        />
                      </div>
                    ) : (
                      <div onClick={() => ctaChangeStatus(x, "true")}>
                        <Icon
                          name={"check"}
                          size="24"
                          className={styles.iconColorGreen}
                        />
                      </div>
                    )}
                    <div onClick={() => ctaDetailsHandler(x)}>
                      <Icon
                        name={"list"}
                        size="24"
                        className={styles.iconColor}
                      />
                    </div>
                    <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColor}
                      />
                    </div>
                    {/* {(!x?.Blog || x?.Blog?.length === 0) && (
                      <div
                        onClick={() => {
                          stateChangeHandler("videoId", x?.id);
                          // setAddBlogVisible(true);
                        }}
                      >
                        <Icon
                          name={"add"}
                          size="24"
                          className={styles.iconColor}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
