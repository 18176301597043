import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideos,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlog,
  getBlogs,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import cn from "classnames";
import {
  addTag,
  deleteAudio,
  deleteTag,
  getTags,
  searchTags,
  updateTag,
  uploadAudioMusic,
} from "../../../redux/slices/tags.slice";
import SearchForm from "../../../components/Form";
import ReactPaginate from "react-paginate";
const Table = () => {
  const dispatch = useDispatch();
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [tagName, setTagName] = useState("");
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { tags, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.tags
  );

  //

  const getTagsData = (currentPage) => {
    dispatch(
      getTags({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getTagsData(currentPage);
  }, [dispatch, currentPage]);

  const addTagList = () => {
    try {
      setTagsLoading(true);
      dispatch(
        addTag({
          payload: { tags: tagsList },
          callback: (data) => {
            if (data?.status === 200) {
              setTagsLoading(false);
              toast.success("Tag added successfully");
              setTagsList([]);
              getTagsData(currentPage);
              setAddVideoModal(false);
            } else {
              setTagsLoading(false);
              toast.error("Failed to add tag");
            }
          },
        })
      );
    } catch (error) {
      setTagsLoading(false);
      toast.error("Failed to add tag");
    }
  };

  const ctaDeleteHandler = (x) => {
    try {
      dispatch(
        deleteTag({
          payload: {
            id: x?.id,
          },
          callback: (data) => {
            if (data?.status === 200) {
              getTagsData(currentPage);
              toast.success("Tag deleted successfully");
            } else {
              toast.error("Failed to delete tag");
            }
          },
        })
      );
    } catch (error) {
      toast.error("Failed to delete tag");
    }
  };

  const ctaEditHandler = (x) => {
    setEditData(x);
    setTagName(x?.name);
    setAddVideoModal(true);
  };
  const updateTagName = () => {
    try {
      setTagsLoading(true);
      dispatch(
        updateTag({
          payload: {
            id: editData?.id,
            name: tagName,
            status: editData?.status,
          },
          callback: (data) => {
            if (data?.status === 200) {
              getTagsData(currentPage);
              setTagsLoading(false);
              setAddVideoModal(false);
              toast.success("Tag updated successfully");
            } else {
              setTagsLoading(false);
              toast.error("Failed to update tag");
            }
          },
        })
      );
    } catch (error) {
      setTagsLoading(false);
      toast.error("Failed to update tag");
    }
  };
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getTags({
            payload: { page: currentPage },
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchTags({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  return (
    <div>
      <Modal
        visible={addVideoModal}
        onClose={() => {
          setEditData("");
          setTagsList([]);
          setTagName("");
          setAddVideoModal(false);
        }}
        title={editData ? "Edit Tags" : "Add Tags"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            editData={editData}
            loading={tagsLoading}
            tags={tagsList}
            setTags={setTagsList}
            onClick={addTagList}
            setTagName={setTagName}
            tagName={tagName}
            updateTagName={updateTagName}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Tag"
          type="text"
          name="search"
          icon="search"
        />
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setAddVideoModal(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Tag
            </div>
            <div className={styles.col} data-label="Video">
              Status
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Created At
            </div>

            <div className={styles.col} data-label="Created At">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : tags?.length > 0 ? (
            tags?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p>#{x?.name}</p>
                </div>
                <div className={styles.col} data-label="Status">
                  <p>{x?.status}</p>
                </div>

                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => ctaDeleteHandler(x)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>
                    <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColorGreen}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}  // Highlight active tab
      />
    </div>
  );
};

export default Table;
