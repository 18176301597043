import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  auth: null,
  loading: false,
  isAuth: false,
  maintenanceMode: false,
};
export const adminSignin = createAsyncThunk(
  "auth/signin",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/admin/signin`,
        data.payload
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const redirect = createAsyncThunk(
  "auth/redirect",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${endpoint}/v1/admin/redirect`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("j-token")}`,
        },
      });
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/admin/changePassword`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const changeWebsiteMode = createAsyncThunk(
  "auth/changeWebsiteMode",
  async (data, thunkAPI) => {
    try {
      console.log(data.payload)
      const response = await axios.post(
        `${endpoint}/v1/admin/update-website-mode`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWebsiteMode = createAsyncThunk(
  "auth/getWebsiteMode",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/admin/get-website-mode`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminSignin.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(adminSignin.fulfilled, (state, {payload}) => {
      state.isAuth = true;
      state.loading = false;
      state.auth = payload?.data;
    });
    builder.addCase(adminSignin.rejected, (state, payload) => {
      state.loading = false;
      state.isAuth = false;
    });
    builder.addCase(redirect.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(redirect.fulfilled, (state, {payload}) => {
      state.isAuth = true;
      state.loading = false;
      state.auth = payload.data;
    });
    builder.addCase(redirect.rejected, (state, payload) => {
      state.loading = false;
      state.isAuth = false;
    });
    builder.addCase(getWebsiteMode.pending, (state) => {
      state.loading = true;
      state.maintenanceMode = false;
    });
    builder.addCase(getWebsiteMode.fulfilled, (state, { payload }) => {
      state.maintenanceMode = payload?.data?.mode == "ACTIVE";
      state.loading = false;
    });
    builder.addCase(getWebsiteMode.rejected, (state, payload) => {
      state.loading = false;
      state.maintenanceMode = false;
    });
  },
});

export const {} = authSlice.actions;
export default authSlice.reducer;
