import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  audio: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const searchAudio = createAsyncThunk(
  "audio/searchAudio",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/audio/search-audio?title=${data?.payload}
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getAudio = createAsyncThunk(
  "audio/getAudio",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/audio?page=${data.payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const uploadAudioMusic = createAsyncThunk(
  "audio/uploadAudio",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/audio/upload-audio`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteAudio = createAsyncThunk(
  "audio/deleteAudio",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${endpoint}/v1/audio/delete-audio/${data?.payload?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const audioSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAudio.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAudio.fulfilled, (state, { payload }) => {
      state.audio = payload?.data?.audios;
      state.loading = false;
      state.page = payload?.data?.page;
      state.pageSize = payload?.data?.pageSize;
      state.totalPages = payload?.data?.totalPages;
    });
    builder.addCase(getAudio.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(searchAudio.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchAudio.fulfilled, (state, { payload }) => {
      state.audio = payload?.audios;
      state.loading = false;
    });
    builder.addCase(searchAudio.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = audioSlice.actions;
export default audioSlice.reducer;
