import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  videos: [],
  loading: false,
  featuredVideos: [],
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const searchVideo = createAsyncThunk(
  "video/searchVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/video/search-video?title=${data?.payload}
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getVideos = createAsyncThunk(
  "video/getVideos",
  async (data, thunkAPI) => {
    console.log("data", data);
    try {
      const response = await axios.get(
        `${endpoint}/v1/admin/video/${data?.payload?.filter}?page=${data?.payload?.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getFeaturedVideos = createAsyncThunk(
  "video/getFeaturedVideos",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/admin/video/get-featured-videos`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getAllVideos = createAsyncThunk(
  "video/getAllVideos",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${endpoint}/v1/video/video-by-admin`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("j-token")}`,
        },
      });
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addVideo = createAsyncThunk(
  "video/addVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v2/video/post-video`,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateManyVideo = createAsyncThunk(
  "video/updateManyVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/video/update-many-videos`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const uploadSharpImage = createAsyncThunk(
  "video/uploadImage",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/video/upload-image`,
        data?.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateVideo = createAsyncThunk(
  "video/updateVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/video/update-video-title`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const verifyVideo = createAsyncThunk(
  "video/verifyVideo",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${endpoint}/v1/admin/${data?.payload?.id}/verify/${data?.payload.status}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVideos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVideos.fulfilled, (state, { payload }) => {
      state.videos = payload?.data;
      state.loading = false;
      state.page = payload?.page;
      state.pageSize = payload?.pageSize;
      state.totalPages = payload?.totalPages;
    });
    builder.addCase(getVideos.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(getFeaturedVideos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeaturedVideos.fulfilled, (state, { payload }) => {
      state.featuredVideos = payload?.data;
      state.loading = false;
      state.page = payload?.page;
      state.pageSize = payload?.pageSize;
      state.totalPages = payload?.totalPages;
    });
    builder.addCase(getFeaturedVideos.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(searchVideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchVideo.fulfilled, (state, { payload }) => {
      state.videos = payload?.videos;
      state.loading = false;
    });
    builder.addCase(searchVideo.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = videoSlice.actions;
export default videoSlice.reducer;
