import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  musics: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};
export const addMusic = createAsyncThunk(
  "music/addMusic",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/music/upload
        `,
        data?.payload,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateMusic = createAsyncThunk(
  "music/updateMusic",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/music/update-music-video
        `,
        data?.payload,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getMusic = createAsyncThunk(
  "music/getMusic",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/music`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const searchMusic = createAsyncThunk(
  "video/searchMusic",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${endpoint}/v1/music/search-music?title=${data?.payload}
        `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteMusic = createAsyncThunk(
  "music/deleteMusic",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/music/delete-music
        `,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const musicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMusic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMusic.fulfilled, (state, { payload }) => {
      state.musics = payload?.data?.videos;
      state.loading = false;
      state.page = payload?.data?.page;
      state.pageSize = payload?.data?.pageSize;
      state.totalPages = payload?.data?.totalPages;
    });
    builder.addCase(getMusic.rejected, (state, payload) => {
      state.loading = false;
    });
    builder.addCase(searchMusic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchMusic.fulfilled, (state, { payload }) => {
      state.musics = payload?.musics;
      state.loading = false;
    });
    builder.addCase(searchMusic.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = musicSlice.actions;
export default musicSlice.reducer;
