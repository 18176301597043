import React, { useState } from "react";
import Table from "./Table/index";
import Icon from "../../components/Icon";

export default function Category() {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 5,
        }}
      >
        <button onClick={()=>setVisible(true)}>
          <Icon name="plus" />
        </button>
      </div>
      <Table setVisible={setVisible} visible={visible} />
    </div>
  );
}
