import React, { useState, useEffect } from "react";
import TextInput from "../../components/TextInput";
import EditorComponent from "../../components/EditorComponent/EditorComponent";
import File from "../../components/File";
import { useDispatch, useSelector } from "react-redux";
import {
  addBlog,
  modifyBlog,
  uploadVideo,
} from "../../redux/slices/blog.slice";
import Icon from "../../components/Icon";
import toast from "react-hot-toast";
import Checkbox from "../../components/Checkbox";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import htmlToDraft from "html-to-draftjs";
import TagInput from "../../components/tagInput/TagInput";
import MultiSelectDropdown from "../../components/multiSelectDropDown/MultiSelectDropdown";
import Modal from "../../components/Modal";
import { addTag, getTags } from "../../redux/slices/tags.slice";

export default function Form({
  categories,
  setVisible,
  getBlogsData,
  videoList,
  editData,
}) {
  const [state, setState] = useState({
    title: editData?.title || "",
    video: "",
    image: editData?.image || "",
    reelUrl: "",
    description: editData?.description || "",
    thumbnailUrl: "",
    category: editData?.category || "",
    isBreaking: editData?.breaking || false,
    videoId: "",
    videoName: "",
    scheduleAt: "",
    waterMark: false,
    selectedSchedule: "",
    authorName: editData?.authorName || "Jewasity Staff",
    credit: editData?.credit || "",
  });
  const [tagName, setTagName] = useState("");
  const [tags, setTags] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [blogUploadLoading, setBlogUploadLoading] = useState(false);
  const [draftBlogUploadLoading, setDraftBlogUploadLoading] = useState(false);
  const [unlistBlogUploadLoading, setUnlistBlogUploadLoading] = useState(false);

  const [description, setDescription] = useState(() => {
    if (editData?.description) {
      const blocksFromHtml = htmlToDraft(editData?.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  const [files, setFiles] = useState([]);

  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditorStateChange = (editorState) => {
    setDescription(editorState);
    const contentState = draftToHtml(
      convertToRaw(editorState?.getCurrentContent())
    );
    stateChangeHandler("description", contentState);
  };

  const addFile = () => {
    setFiles([
      ...files,
      {
        id: files.length + 1,
        title: `Blog Video ${files.length + 1}`,
        target: "",
        file: null,
      },
    ]);
  };

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      toast.error("No file selected");
      return false;
    }

    const validTypes = ["image/png", "image/webp", "image/jpeg", "image/jpg"];

    if (validTypes.includes(file.type)) {
      setState((prevState) => ({
        ...prevState,
        image: file,
      }));
    } else {
      toast.error("File must be in png, webp, or jpg format");
    }
  };

  const removeFile = (id) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const dispatch = useDispatch();

  const handleFileChange = async (id, key, value) => {
    try {
      // setVideoLoading(true);
      if (value) {
        setFiles(
          files.map((file) =>
            file.id === id ? { ...file, file: value } : file
          )
        );
        // const formData = new FormData();
        // formData.append("file", value);
        // dispatch(
        //   uploadVideo({
        //     payload: formData,
        //     callback: (data) => {
        //       if (data?.status === 200) {
        //         setVideoLoading(false);
        //         setFiles(
        //           files.map((file) =>
        //             file.id === id
        //               ? {
        //                   ...file,
        //                   [key]: value,
        //                   videoUrl: data?.videoUrl,
        //                   waterMarkVideoUrl: data?.watermarkVideoUrl,
        //                   loading: false,
        //                 }
        //               : file
        //           )
        //         );
        //         setVideoLoading(false);
        //       }
        //     },
        //   })
        // );
      }
    } catch (error) {
      console.error("File upload failed", error);
      // setVideoLoading(false);
    }
  };
  console.log("files", files);

  const validateForm = () => {
    if (!state?.title) {
      toast.error("Title is required");
      return false;
    }

    // if (!state?.category) {
    //   toast.error("Category is required");
    //   return false;
    // }
    if (!state?.image?.name) {
      toast.error("Image is required");
      return false;
    }
    if (!state?.description) {
      toast.error("Description is required");
      return false;
    }
    // if (!files[0]?.videoUrl) {
    //   toast.error("Video is required");
    //   return false;
    // }
    return true;
  };

  const uploadBlog = () => {
    try {
      if (!validateForm()) return; // Exit if validation fails
      setBlogUploadLoading(true);
      const formData = new FormData();
      const videoLinks = files?.map((file) => file?.file);
      const waterMarkVideoUrl = files?.map((file) => file?.waterMarkVideoUrl);
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      formData.append("file", state?.image);
      formData.append("thumbnailUrl", state?.thumbnailUrl);
      for (let i = 0; i < videoLinks.length; i++) {
        formData.append(`videofile${i}`, videoLinks[i]);  // Add files under the same key with []
    }
      // formData.append("videoLinks", files);
      // formData.append(
      //   "waterMarkVideoUrl",
      //   !state.waterMark ? files : files
      // );
      formData.append("videoId", state?.videoId);
      formData.append("category", state?.category || "Uncategorized");
      formData.append("isBreaking", state?.isBreaking);
      formData.append("status", "ACTIVE");
      formData.append("scheduledAt", state?.scheduleAt);
      formData.append("tags", tags);
      formData.append("authorName", state?.authorName);
      formData.append("applyWatermark", state.waterMark);
      formData.append("credit", state.credit);

      dispatch(
        addBlog({
          payload: formData,
          callback: (data) => {
            if (data?.status === 200) {
              setState({
                title: "",
                video: "",
                image: "",
                reelUrl: "",
                description: "",
                thumbnailUrl: "",
                category: "",
                isBreaking: false,
                authorName: "",
                credit: "",
              });
              setTags([]);
              setVisible(false);
              setBlogUploadLoading(false);
              getBlogsData();
              setFiles([{ id: 1, title: "Blog Video 1", target: "" }]);
              toast.success("Blog Uploaded Successfully");
            } else {
              toast.error("Something went wrong");
              setBlogUploadLoading(false);
            }
          },
        })
      );
    } catch (error) {
      setBlogUploadLoading(false);
      toast.error("Something went wrong");
    }
  };
  const uploadDraftBlog = () => {
    try {
      if (state.scheduleAt) {
        toast.error("Draft Blog Cannot be Schedule");
        return false;
      }
      if (!state?.title) {
        toast.error("Title is required");
        return false;
      }

      // if (!state?.category) {
      //   toast.error("Category is required");
      //   return false;
      // }
      if (!state?.image?.name) {
        toast.error("Image is required");
        return false;
      }
      // if (!files[0]?.videoUrl) {
      //   toast.error("Video is required");
      //   return false;
      // }
      setDraftBlogUploadLoading(true);
      const formData = new FormData();
      const videoLinks = files?.map((file) => file?.videoUrl);
      const waterMarkVideoUrl = files?.map((file) => file?.waterMarkVideoUrl);
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      formData.append("file", state?.image);
      formData.append("thumbnailUrl", state?.thumbnailUrl);
      for (let i = 0; i < videoLinks.length; i++) {
        formData.append(`videofile${i}`, videoLinks[i]);  // Add files under the same key with []
    }
    // Add files under the same key with []

      // formData.append("videoLinks", videoLinks || []);
      // formData.append(
      //   "waterMarkVideoUrl",
      //   !state.waterMark ? videoLinks : waterMarkVideoUrl
      // );
      formData.append("videoId", state?.videoId);
      formData.append("category", state?.category || "Uncategorized");
      formData.append("isBreaking", state?.isBreaking);
      formData.append("scheduledAt", state?.scheduleAt);
      formData.append("tags", tags);
      formData.append("status", "DRAFT");
      formData.append("authorName", state?.authorName);
      formData.append("applyWatermark", state.waterMark);
      formData.append("credit", state.credit);
      dispatch(
        addBlog({
          payload: formData,
          callback: (data) => {
            if (data?.status === 200) {
              setState({
                title: "",
                video: "",
                image: "",
                reelUrl: "",
                description: "",
                thumbnailUrl: "",
                category: "",
                isBreaking: false,
                authorName: "",
                credit: "",
              });
              setTags([]);
              setVisible(false);
              setDraftBlogUploadLoading(false);
              getBlogsData();
              setFiles([{ id: 1, title: "Blog Video 1", target: "" }]);
              toast.success("Blog Marked As Draft");
            } else {
              toast.error("Something went wrong");
              setDraftBlogUploadLoading(false);
            }
          },
        })
      );
    } catch (error) {
      setDraftBlogUploadLoading(false);
      toast.error("Something went wrong");
    }
  };
  const uploadUnlistBlog = () => {
    try {
      if (state.scheduleAt) {
        toast.error("Unlist Blog Cannot be Schedule");
        return false;
      }
      if (!validateForm()) return; // Exit if validation fails
      setUnlistBlogUploadLoading(true);
      const formData = new FormData();
      const videoLinks = files?.map((file) => file?.videoUrl);
      const waterMarkVideoUrl = files?.map((file) => file?.waterMarkVideoUrl);
      formData.append("title", state?.title);
      formData.append("description", state?.description);
      formData.append("file", state?.image);
      formData.append("thumbnailUrl", state?.thumbnailUrl);
      for (let i = 0; i < videoLinks.length; i++) {
        formData.append(`videofile${i}`, videoLinks[i]);  // Add files under the same key with []
    }
      // formData.append("videoLinks", videoLinks || []);

      // formData.append(
      //   "waterMarkVideoUrl",
      //   !state.waterMark ? videoLinks : waterMarkVideoUrl
      // );
      formData.append("videoId", state?.videoId);
      formData.append("category", state?.category || "Uncategorized");
      formData.append("isBreaking", state?.isBreaking);
      formData.append("status", "UNLISTED");
      formData.append("scheduledAt", state?.scheduleAt);
      formData.append("tags", tags);
      formData.append("authorName", state?.authorName);
      formData.append("applyWatermark", state.waterMark);
      formData.append("credit", state.credit);
      dispatch(
        addBlog({
          payload: formData,
          callback: (data) => {
            if (data?.status === 200) {
              setState({
                title: "",
                video: "",
                image: "",
                reelUrl: "",
                description: "",
                thumbnailUrl: "",
                category: "",
                isBreaking: false,
                authorName: "",
                credit: "",
              });
              setTags([]);
              setVisible(false);
              setUnlistBlogUploadLoading(false);
              getBlogsData();
              setFiles([{ id: 1, title: "Blog Video 1", target: "" }]);
              toast.success("Blog Unlist Successfully");
            } else {
              toast.error("Something went wrong");
              setUnlistBlogUploadLoading(false);
            }
          },
        })
      );
    } catch (error) {
      setUnlistBlogUploadLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateBlog = () => {
    try {
      if (!state?.title) {
        toast.error("Title is required");
        return false;
      }

      // if (!state?.category) {
      //   toast.error("Category is required");
      //   return false;
      // }
      if (!state?.image) {
        toast.error("Image is required");
        return false;
      }

      if (!state?.description) {
        toast.error("Description is required");
        return false;
      }
      // if (!files[0]?.videoUrl) {
      //   toast.error("Video is required");
      //   return false;
      // }
      setBlogUploadLoading(true);
      const formData = new FormData();
      const videoLinks = files?.map((file) => file?.videoUrl);
      const waterMarkVideoUrl = files?.map((file) => file?.waterMarkVideoUrl);
      formData.append("title", state?.title);
      formData.append("id", editData?.id);
      formData.append("description", state?.description);
      formData.append("file", state?.image);
      formData.append("thumbnailUrl", state?.thumbnailUrl);
      for (let i = 0; i < videoLinks.length; i++) {
        formData.append(`videofile${i}`, videoLinks[i]);  // Add files under the same key with []
    }
      // formData.append("videoLinks", videoLinks);
      // formData.append(
      //   "waterMarkVideoUrl",
      //   !state.waterMark ? videoLinks : waterMarkVideoUrl
      // );
      formData.append("videoId", state?.videoId);
      formData.append("category", state?.category || "Uncategorized");
      formData.append("isBreaking", state?.isBreaking);
      formData.append("scheduledAt", state?.scheduleAt);
      formData.append("tags", tags);
      formData.append(
        "status",
        editData?.status === "UNLISTED" ? "UNLISTED" : "ACTIVE"
      );
      formData.append("authorName", state?.authorName);
      formData.append("applyWatermark", state.waterMark);
      formData.append("credit", state.credit);
      dispatch(
        modifyBlog({
          payload: formData,
          callback: (data) => {
            setBlogUploadLoading(false);
            if (data?.status === 200) {
              setState({
                title: "",
                video: "",
                image: "",
                reelUrl: "",
                description: "",
                thumbnailUrl: "",
                category: "",
                isBreaking: false,
                authorName: "",
                credit: "",
              });
              setTags([]);
              setVisible(false);
              getBlogsData();
              setFiles([{ id: 1, title: "Blog Video 1", target: "" }]);
              toast.success("Blog Updated Successfully");
            } else {
              toast.error("Something went wrong");
              setBlogUploadLoading(false);
            }
          },
        })
      );
    } catch (error) {
      setBlogUploadLoading(false);
      toast.error("Something went wrong");
    }
  };
  const [multiImageUpload, setMultiImageUpload] = useState(false);
  const { tags: tagsList } = useSelector((state) => state.tags);
  const [tagModal, setTagModal] = useState(false);
  const [newTagsList, setNewTagsList] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const categoryList = categories?.map((item) => {
    return item?.title;
  });
  const handleOpenTagModal = () => {
    setTagModal(true);
  };
  const getTagsData = () => {
    dispatch(
      getTags({
        payload: "",
        callback: (data) => {
          if (data?.status === 200) {
          } else {
          }
        },
      })
    );
  };
  const addTagList = () => {
    try {
      setTagsLoading(true);
      dispatch(
        addTag({
          payload: { tags: newTagsList },
          callback: (data) => {
            if (data?.status === 200) {
              setTagsLoading(false);
              toast.success("Tag added successfully");
              setNewTagsList([]);
              getTagsData();
              setTagModal(false);
            } else {
              setTagsLoading(false);
              toast.error("Failed to add tag");
            }
          },
        })
      );
    } catch (error) {
      setTagsLoading(false);
      toast.error("Failed to add tag");
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 24,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Modal
        visible={tagModal}
        onClose={() => {
          setTagModal(false);
        }}
        title={"Add New Tag"}
      >
        <div>
          <TagInput
            label="Tags"
            className={""}
            name="text"
            type="text"
            placeholder="Tags"
            required
            tags={newTagsList}
            setTags={setNewTagsList}
          />
          <button
            onClick={addTagList}
            disabled={false}
            style={{
              backgroundColor: "#ff3c00",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              padding: 10,
              cursor: tagsLoading ? "no-drop" : "pointer",
              width: "100%",
            }}
          >
            {tagsLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader height={25} width={25} />
              </div>
            ) : (
              "Add tags"
            )}
          </button>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
        }}
      >
        {!editData && (
          <div
            style={{
              marginTop: 4,
            }}
          >
            <Checkbox
              content={"Add Water Mark"}
              value={state.waterMark}
              onChange={(e) =>
                stateChangeHandler("waterMark", !state.waterMark)
              }
            />
          </div>
        )}
        <div
          style={{
            marginTop: 4,
          }}
        >
          <Checkbox
            content={"Breaking News"}
            value={state.isBreaking}
            onChange={(e) =>
              stateChangeHandler("isBreaking", !state.isBreaking)
            }
          />
        </div>
      </div>
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={state?.title}
        onChange={(e) => {
          stateChangeHandler("title", e.target.value);
        }}
      />
      <TextInput
        label="Author Name (Optional)"
        className={""}
        name="text"
        type="text"
        placeholder="Author Name"
        required
        value={state?.authorName}
        onChange={(e) => {
          stateChangeHandler("authorName", e.target.value);
        }}
      />
      {/* <TagInput
        label="Tags"
        className={""}
        name="text"
        type="text"
        placeholder="Tags"
        required
        tags={tags}
        setTags={setTags}
      /> */}
      <MultiSelectDropdown
        label={"Tags"}
        options={tagsList}
        value={tagName}
        setValue={setTagName}
        tags={tags}
        setTags={setTags}
        handleOpenTagModal={handleOpenTagModal}
      />
      {!editData && (
        <TextInput
          label="Schedule At (Optional)"
          className={""}
          name="text"
          type="datetime-local"
          placeholder="scheduleAt"
          required
          value={state?.selectedSchedule}
          onChange={(e) => {
            const localDateTime = moment(e.target.value)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            stateChangeHandler("scheduleAt", localDateTime);
            stateChangeHandler("selectedSchedule", e.target.value);
          }}
        />
      )}

      <Dropdown
        label={"Category (Optional)"}
        options={categoryList}
        value={state.category}
        setValue={(value) => {
          if (value) {
            stateChangeHandler("category", value);
          }
        }}
      />
      <Dropdown
        label={"Select Video (Optional)"}
        options={videoList}
        value={state.videoName || "Video Name"}
        setValue={(value) => {
          if (value == "") {
            stateChangeHandler("videoName", "");
            setTimeout(() => {
              stateChangeHandler("videoId", "");
            }, 1000);
          } else {
            stateChangeHandler("videoName", value?.title);
            setTimeout(() => {
              stateChangeHandler("videoId", value?.id);
            }, 1000);
          }
        }}
      />
      <File
        title={
          state?.image?.name
            ? state?.image?.name?.slice(0, 6) + "..."
            : state?.image
            ? "Image Uploaded"
            : "Upload Image"
        }
        accept={"image/*"}
        onChange={handleImageFileChange}
        label={"Image"}
      />
      <TextInput
        label="Image Credit (Optional)"
        className={""}
        name="text"
        type="text"
        placeholder="Image Credit"
        required
        value={state?.credit}
        onChange={(e) => {
          stateChangeHandler("credit", e.target.value);
        }}
      />
      <EditorComponent
        label={"Description"}
        state={description}
        onChange={handleEditorStateChange}
        setMultiImageUpload={setMultiImageUpload}
        multiImageUpload={multiImageUpload}
      />
      {files.map((file, index) => (
        <div
          key={file.id}
          style={{
            position: "relative",
          }}
        >
          <File
            title={
              file?.file
                ? `Video Uploaded`
                : file.videoUrl
                ? `Video Uploaded ${index + 1}`
                : file.title
            }
            label={file.title}
            // videoLoading={file?.loading}
            onChange={(e) =>
              handleFileChange(file.id, `file${file.id}`, e.target.files[0])
            }
            accept={"video/*"}
          />
          <button
            onClick={() => removeFile(file.id)}
            style={{
              backgroundColor: "#dc3545",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              height: 50,
              padding: "15px 15px",
              cursor:
                videoLoading ||
                blogUploadLoading ||
                draftBlogUploadLoading ||
                unlistBlogUploadLoading ||
                multiImageUpload
                  ? "no-drop"
                  : "pointer",
              position: "absolute",
              right: 10,
              top: 50,
            }}
            disabled={
              blogUploadLoading ||
              videoLoading ||
              draftBlogUploadLoading ||
              unlistBlogUploadLoading ||
              multiImageUpload
            }
          >
            <Icon name="basket" fill="#fff" height={25} width={25} />
          </button>
        </div>
      ))}

      <button
        onClick={addFile}
        disabled={
          videoLoading ||
          blogUploadLoading ||
          draftBlogUploadLoading ||
          unlistBlogUploadLoading ||
          multiImageUpload
        }
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          cursor:
            videoLoading ||
            blogUploadLoading ||
            draftBlogUploadLoading ||
            unlistBlogUploadLoading ||
            multiImageUpload
              ? "no-drop"
              : "pointer",
          alignSelf: "flex-start",
        }}
      >
        + Add Blog Video
      </button>
      <div
        style={{
          display: editData ? "none" : "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <button
          onClick={uploadDraftBlog}
          disabled={
            videoLoading ||
            blogUploadLoading ||
            draftBlogUploadLoading ||
            unlistBlogUploadLoading ||
            multiImageUpload
          }
          style={{
            backgroundColor: "#ff3c00",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 40,
            paddingRight: 40,
            cursor:
              videoLoading ||
              blogUploadLoading ||
              draftBlogUploadLoading ||
              unlistBlogUploadLoading ||
              multiImageUpload
                ? "no-drop"
                : "pointer",
          }}
        >
          {draftBlogUploadLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader height={25} width={25} />
            </div>
          ) : (
            "Draft"
          )}
        </button>
        <button
          onClick={
            videoLoading ||
            blogUploadLoading ||
            draftBlogUploadLoading ||
            unlistBlogUploadLoading ||
            multiImageUpload
              ? null
              : uploadUnlistBlog
          }
          disabled={
            videoLoading ||
            blogUploadLoading ||
            draftBlogUploadLoading ||
            unlistBlogUploadLoading ||
            multiImageUpload
          }
          style={{
            backgroundColor: "#ff3c00",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 40,
            paddingRight: 40,
            cursor:
              videoLoading ||
              blogUploadLoading ||
              draftBlogUploadLoading ||
              unlistBlogUploadLoading ||
              multiImageUpload
                ? "no-drop"
                : "pointer",
          }}
        >
          {unlistBlogUploadLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader height={25} width={25} />
            </div>
          ) : (
            "Unlist"
          )}
        </button>
        {state.scheduleAt && (
          <button
            onClick={
              videoLoading ||
              blogUploadLoading ||
              draftBlogUploadLoading ||
              unlistBlogUploadLoading ||
              multiImageUpload
                ? null
                : () => stateChangeHandler("scheduleAt", "")
            }
            disabled={
              videoLoading ||
              blogUploadLoading ||
              draftBlogUploadLoading ||
              unlistBlogUploadLoading ||
              multiImageUpload
            }
            style={{
              backgroundColor: "#ff3c00",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 40,
              paddingRight: 40,
              cursor:
                videoLoading ||
                blogUploadLoading ||
                draftBlogUploadLoading ||
                unlistBlogUploadLoading ||
                multiImageUpload
                  ? "no-drop"
                  : "pointer",
            }}
          >
            Remove Schedule
          </button>
        )}
      </div>
      <button
        onClick={
          videoLoading ||
          blogUploadLoading ||
          draftBlogUploadLoading ||
          unlistBlogUploadLoading ||
          multiImageUpload
            ? null
            : editData
            ? updateBlog
            : uploadBlog
        }
        disabled={
          videoLoading ||
          blogUploadLoading ||
          draftBlogUploadLoading ||
          unlistBlogUploadLoading ||
          multiImageUpload
        }
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          padding: 10,
          cursor:
            videoLoading ||
            blogUploadLoading ||
            draftBlogUploadLoading ||
            unlistBlogUploadLoading ||
            multiImageUpload
              ? "no-drop"
              : "pointer",
        }}
      >
        {blogUploadLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : editData ? (
          "Update Blog"
        ) : (
          "Add Blog"
        )}
      </button>
    </div>
  );
}
