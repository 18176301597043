import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideos,
  searchVideo,
  uploadSharpImage,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlogImage,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import cn from "classnames";
import SearchForm from "../../../components/Form";
import {
  deleteMusic,
  getMusic,
  searchMusic,
  updateMusic,
} from "../../../redux/slices/music.slice";
import Loader from "../../../components/Loader";
import { addMusic } from "../../../redux/slices/music.slice";
import { getInfluencers } from "../../../redux/slices/influencity.slice";
import ReactPaginate from "react-paginate";
const Table = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { musics, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.music
  );

  //
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [optionalThumbnail, setOptionalThumbnail] = useState(null);

  const [state, setState] = useState({
    title: "",
    reelUrl: "",
    thumbnailUrl: "",
    waterMarkVideoUrl: "",
    influencer: "",
    influencerId: "",
  });
  const [videoLoading, setVideoLoading] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [featuredVideoLoading, setFeaturedVideoLoading] = useState(false);

  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch = useDispatch();
  const getInfluencityData = () => {
    dispatch(
      getInfluencers({
        payload: "",
        callback: (data) => {
          if (data?.status === 200) {
            console.log(data);
          } else {
          }
        },
      })
    );
  };
  useEffect(() => {
    getInfluencityData();
  }, [dispatch]);
  const handleReelFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    stateChangeHandler("reelUrl", file);
    // try {
    //   setVideoLoading(true);
    //   const formData = new FormData();
    //   if (file.type === "video/mp4") {
    //     formData.append("file", file);
    //     formData.append("type", "music");
    //     if (!optionalThumbnail) {
    //       dispatch(
    //         uploadVideo({
    //           payload: formData,
    //           callback: (data) => {
    //             if (data?.status === 200) {
    //               dispatch(
    //                 videoThumbnail({
    //                   payload: formData,
    //                   callback: (datainner) => {
    //                     if (datainner?.status === 200) {
    //                       setState({
    //                         ...state,
    //                         reelUrl: data?.videoUrl,
    //                         thumbnailUrl: datainner?.thumbnailUrl,
    //                         waterMarkVideoUrl: data?.watermarkVideoUrl,
    //                       });
    //                       setVideoLoading(false);
    //                     } else {
    //                       toast.error(
    //                         "Something went wrong while fetching thumbnail"
    //                       );
    //                       setVideoLoading(false);
    //                     }
    //                   },
    //                 })
    //               );
    //             } else {
    //               toast.error(data?.message);
    //               setVideoLoading(false);
    //             }
    //           },
    //         })
    //       );
    //     } else {
    //       dispatch(
    //         uploadVideo({
    //           payload: formData,
    //           callback: (data) => {
    //             if (data?.status === 200) {
    //               setState({
    //                 ...state,
    //                 reelUrl: data?.videoUrl,
    //                 waterMarkVideoUrl: data?.watermarkVideoUrl,
    //               });
    //               setVideoLoading(false);
    //             } else {
    //               toast.error(data?.message);
    //               setVideoLoading(false);
    //             }
    //           },
    //         })
    //       );
    //     }
    //   } else {
    //     toast.error("File must be mp4");
    //     setVideoLoading(false);
    //   }
    // } catch (error) {
    //   console.error("Error handling file change:", error);
    //   toast.error("An error occurred while handling the file change");
    //   setVideoLoading(false);
    // }
  };
  const uploadFeaturedVideo = () => {
    // Simple validation checks
    const validateForm = () => {
      if (!state?.title) {
        toast.error("Title is required");
        return false;
      }
      if (!state?.reelUrl) {
        toast.error("Reel Video is required");
        return false;
      }
      return true;
    };

    try {
      if (!validateForm()) return; // Exit if validation fails
      setFeaturedVideoLoading(true);
      const formData = new FormData();
      formData.append("title", state?.title);
      if (state?.thumbnailUrl) {
        formData.append("thumbnailUrl", state?.thumbnailUrl);
      }
      if (state?.reelUrl) {
        formData.append("videoUrl", state?.reelUrl);
      }
      if (state?.influencerId) {
        formData.append("influencerId", state?.influencerId);
      }
      // const data = {
      //   title: state?.title,
      //   thumbnailUrl: state?.thumbnailUrl,
      //   videoUrl: state?.reelUrl,
      //   waterMarkVideoUrl: state?.waterMarkVideoUrl,
      //   influasityId: state?.influencerId,
      // };
      dispatch(
        addMusic({
          payload: formData,
          callback: (data) => {
            setFeaturedVideoLoading(false);
            if (data?.status === 200) {
              setState({
                title: "",
                reelUrl: "",
                thumbnailUrl: "",
                influencerId: "",
              });
              getMusicData();
              setEditModalVisible(false);
              setAddVideoModal(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setFeaturedVideoLoading(false);
      toast.error("Something went wrong");
    }
  };

  const ctaEditHandler = (data) => {
    setEdit(data);
    setState({
      ...state,
      title: data?.title,
      reelUrl: data?.videoUrl,
      thumbnailUrl: data?.thumbnailUrl,
    });
    setEditModalVisible(true);
  };
  const getMusicData = (filter, currentPage) => {
    dispatch(
      getMusic({
        payload: { filter, page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  const updateMusicVideo = () => {
    try {
      if (!state?.title) {
        toast.error("Title is required");
        return false;
      }
      setFeaturedVideoLoading(true);
      dispatch(
        updateMusic({
          payload: {
            id: edit?.id,
            title: state?.title,
          },
          callback: (data) => {
            if (data?.status === 200) {
              setFeaturedVideoLoading(false);
              getMusicData();
              setEditModalVisible(false);
              setAddVideoModal(false);
              setEdit(null);
              setState({
                title: "",
                reelUrl: "",
                thumbnailUrl: "",
              });
              toast.success(data?.message);
            } else {
              setFeaturedVideoLoading(false);
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setFeaturedVideoLoading(false);
      toast.error("Something went wrong");
    }
  };
  const ctaChangeStatus = (item) => {
    dispatch(
      deleteMusic({
        payload: {
          id: item?.id,
        },
        callback: (data) => {
          if (data?.status === 200) {
            toast.success("Deleted Successfully");
            getMusicData("true", currentPage);
          } else {
            toast.success("Something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getMusicData("true", currentPage);
  }, [dispatch, currentPage]);

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getMusic({
            payload: { filter: "true", currentPage },
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchMusic({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  const handleReelImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    stateChangeHandler("thumbnailUrl", file);
    // setOptionalThumbnail(file);
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("type", "video");
    // try {
    //   dispatch(
    //     uploadSharpImage({
    //       payload: formData,
    //       callback: (dataInner) => {
    //         if (dataInner?.status === 200) {
    //           stateChangeHandler("thumbnailUrl", dataInner?.imageUrl);
    //           setThumbnailLoading(false);
    //         }
    //       },
    //     })
    //   );
    // } catch (error) {
    //   console.error("Error handling file change:", error);
    //   toast.error("An error occurred while handling the file change");
    //   setThumbnailLoading(false);
    // }
  };
  return (
    <div>
      <Modal
        visible={edit ? editModalVisible : addVideoModal}
        onClose={
          edit
            ? () => {
                setEdit(null);
                setState({
                  title: "",
                  reelUrl: "",
                  thumbnailUrl: "",
                });
                setEditModalVisible(false);
              }
            : () => {
                setEdit(null);
                setState({
                  title: "",
                  reelUrl: "",
                  thumbnailUrl: "",
                });
                setAddVideoModal(false);
              }
        }
        title={edit ? "Edit Music Video" : "Add Music Video"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            handleReelFileChange={handleReelFileChange}
            videoLoading={videoLoading}
            featuredVideoLoading={featuredVideoLoading}
            uploadFeaturedVideo={uploadFeaturedVideo}
            stateChangeHandler={stateChangeHandler}
            state={state}
            isEdit={edit}
            updateMusicVideo={updateMusicVideo}
            thumbnail={optionalThumbnail}
            setthumbnail={setOptionalThumbnail}
            handleReelImageChange={handleReelImageChange}
            thumbnailLoading={thumbnailLoading}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Music Video"
          type="text"
          name="search"
          icon="search"
        />

        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={
            edit
              ? () => {
                  setEdit(null);
                  setEditModalVisible(false);
                }
              : () => {
                  setEdit(null);
                  setAddVideoModal(true);
                }
          }
        >
          <Icon name="plus" size="18" />
        </button>
      </div>

      <div
        className={styles.wrapper}
        style={{
          overflow: "scroll",
        }}
      >
        <div className={styles.table}>
          <div
            className={styles.rowHeading}
            style={{
              width: "100%",
            }}
          >
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Video">
              Video
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Thumbnail
            </div>
            {/* <div className={styles.col} data-label="Status">
              Status
            </div>
            <div className={styles.col} data-label="Featured">
              Sponsored
            </div> */}
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : musics?.length > 0 ? (
            musics?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {x?.title}
                  </p>
                </div>
                <div className={styles.col} data-label="Video">
                  <a
                    href={x?.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    Video_URL
                  </a>
                </div>
                <div className={styles.col} data-label="Thumbnail">
                  <a
                    href={x?.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    <img src={x?.thumbnailUrl} height={140} width={160} />
                  </a>
                </div>
                {/* <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.verified ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.verified ? "Verified" : "Pending"}
                  </span>
                </div> */}
                {/* <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.featured ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.featured ? "Yes" : "No"}
                  </span>
                </div> */}
                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => ctaChangeStatus(x)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>

                    <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
