import React from "react";
import Table from "./Table/index";

export default function Influencity() {
  return (
    <div>
      <Table />
    </div>
  );
}
