import React from "react";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Editor from "../../../../components/Editor";
import Loader from "../../../../components/Loader";

export default function Form({ title, setTitle, loading, addCategory }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <button
        // className={cn("button", styles.button)}
        style={{
          backgroundColor: "#ff3c00",
          width: "100%",
          height: 50,
          borderRadius: 30,
          color: "white",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          justifyContent: "center",
          opacity: loading ? 0.5 : 1,
          cursor: loading ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (loading) {
            return;
          } else {
            addCategory();
          }
        }}
        disabled={loading}
      >
        {loading ? <Loader white={"white"} /> : "Add Category"}
      </button>
    </div>
  );
}
